import { TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes, useLocation } from "react-router-dom"

import BoxRoutes from "./box/routes"

export default function GatewayRoutes() {
  /* Tabs to ListLayout component*/
  const location = useLocation()
  const { t } = useTranslation(["configuration"])
  const [tabs, setTabs] = useState([
    {
      name: t("Boxes"),
      active: location.pathname.includes("boxes"),
      url: "/configuration/gateways/boxes/"
    }
  ])

  return (
    <Routes>
      <Route
        element={
          <TabsLayout
            title={t("Gateways")}
            tabs={tabs}
            setTabs={setTabs}
          />
        }
      >
        <Route
          index
          path="boxes/*"
          element={<BoxRoutes />}
        />
      </Route>
    </Routes>
  )
}
