import { TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes, useLocation, useParams } from "react-router-dom"
import Create from "./components/Create"
import List from "./components/List"
import ConfirmedCurtailSchedulesRoutes from "./confirmed-load-curtail-schedule/routes"
import FlexOptiRoutes from "./flex-opti/routes"
import LoadCurtailParametersRoutes from "./load-curtail-parameters/routes"
import MarketPlayerInteractionRoutes from "./market-player-interaction/routes"
import ProposedCurtailSchedulesRoutes from "./proposed-load-curtail-schedule/routes"

function FlexLayout() {
  const { siteId, flexId } = useParams()
  const location = useLocation()
  const { t } = useTranslation(["assets"])
  const [tabs, setTabs] = useState([
    {
      name: t("Curtail parameters"),
      active: location.pathname.includes("load-curtail-parameters"),
      url: `/sites/${siteId}/optimization/flexibility/${flexId}/load-curtail-parameters/`
    },
    {
      name: t("Flex opti"),
      active: location.pathname.includes("flex-opti"),
      url: `/sites/${siteId}/optimization/flexibility/${flexId}/flex-opti/`
    },
    {
      name: t("Proposed curtail schedules"),
      active: location.pathname.includes("proposed-curtail-schedules"),
      url: `/sites/${siteId}/optimization/flexibility/${flexId}/proposed-curtail-schedules/`
    },
    {
      name: t("Confirmed curtail schedules"),
      active: location.pathname.includes("confirmed-curtail-schedules"),
      url: `/sites/${siteId}/optimization/flexibility/${flexId}/confirmed-curtail-schedules/`
    },
    {
      name: t("Market player interaction"),
      active: location.pathname.includes("market-player-interaction"),
      url: `/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/`
    }
  ])

  return (
    <TabsLayout
      title={t("Flexibility")}
      tabs={tabs}
      setTabs={setTabs}
    />
  )
}

export default function FlexibilityRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="/create/"
        element={<Create />}
      />
      <Route
        path="/:flexId/"
        element={<FlexLayout />}
      >
        <Route
          index
          path="load-curtail-parameters/*"
          element={<LoadCurtailParametersRoutes />}
        />
        <Route
          index
          path="flex-opti/*"
          element={<FlexOptiRoutes />}
        />
        <Route
          index
          path="proposed-curtail-schedules/*"
          element={<ProposedCurtailSchedulesRoutes />}
        />
        <Route
          index
          path="confirmed-curtail-schedules/*"
          element={<ConfirmedCurtailSchedulesRoutes />}
        />
        <Route
          index
          path="market-player-interaction/*"
          element={<MarketPlayerInteractionRoutes />}
        />
      </Route>
    </Routes>
  )
}
