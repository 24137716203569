import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/points/`, config({ token, queryParams }))
}

export async function retrieve(siteId, id, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/points/${id}/`, config({ token }))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/points/`, data, config({ token }))
}

export async function update(siteId, id, data, token) {
  return await axios.put(`${window.BACKEND_URL}/asset/sites/${siteId}/points/${id}/`, data, config({ token }))
}

export async function remove(siteId, id, token) {
  return await axios.delete(`${window.BACKEND_URL}/asset/sites/${siteId}/points/${id}/`, config({ token }))
}

export async function getEquipments(siteId, token, queryParams) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/`, config({ token, queryParams }))
}

export async function getEquipmentAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/allvalues`, config({ token }))
}

export async function getAutomationPoints(siteId, token, queryParams) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/`, config({ token, queryParams }))
}

export async function getAutomationPointsAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/automation_points/allvalues`, config({ token }))
}

export async function getParameters(token) {
  return await axios.get(`${window.BACKEND_URL}/asset/parameters/`, config({ token }))
}

export async function getStores(siteId, storeType, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/stores/`, config({ token, queryParams: { type: storeType } }))
}

export async function removePoints(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/points/deletion/`, ids, config({ token }))
}

export async function getPointsCount(ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/points/count/`, ids, config({ token }))
}

export async function getAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/points/allvalues`, config({ token }))
}

export async function exportPoints(siteId, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/${siteId}/points/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importPoints(siteId, file, token) {
  let formData = new FormData()
  formData.append("file", file)
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/points/bulk/import`, formData, config({ token, file: true }), { signal: new AbortController() })
}
