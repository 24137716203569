import { AggregatorRoutesContext } from "/apps/configuration/energy-company/aggregator/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["configuration"])
  const { aggregator } = useContext(AggregatorRoutesContext)
  const [processing, setProcessing] = useState(false)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    setProcessing(true)
    await remove(aggregator.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The aggregator has been removed."))])
        navigate("/configuration/energy-company/aggregators/")
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        displayNotification([errorNotification("Error", message)])
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    aggregator &&
      aggregator.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Energy companies"), url: "/configuration/energy-company/distributors/" },
          { label: t("Aggregators"), url: "/configuration/energy-company/aggregators/" },
          { label: aggregator.name, url: `/configuration/energy-company/aggregators/${aggregator.id}/` },
          { label: t("Delete"), url: `/configuration/energy-company/aggregators/${aggregator.id}/delete/` }
        ])
      )
  }, [aggregator])

  return (
    <>
      {aggregator && (
        <DeleteConfirmation
          objectType={t("Aggregator")}
          objectNames={[aggregator.name]}
          remove={removeObject}
          processing={processing}
          goBack={() => navigate(-1)}
        />
      )}
    </>
  )
}
