import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/`, config({ token, queryParams }))
}

export async function retrieve(siteId, nodeId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/${nodeId}/`, config({ token }))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/`, data, config({ token }))
}

export async function update(siteId, nodeId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/${nodeId}/`, data, config({ token }))
}

export async function remove(siteId, nodeId, token) {
  return await axios.delete(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/${nodeId}/`, config({ token }))
}

export async function removeNodes(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/deletion/`, ids, config({ token }))
}

export async function getCount(nodeId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/nodes/${nodeId}/count`, config({ token }))
}

export async function getSpaces(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, config({ token }))
}

export async function getSMNodes(siteId, token, queryParams) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/`, config({ token, queryParams: { ...queryParams, type: "smnode" } }))
}

export async function getCarelNodes(siteId, token, queryParams) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/`, config({ token, queryParams: { ...queryParams, type: "carelnode" } }))
}

export async function getNodesCount(ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/nodes/count/`, ids, config({ token }))
}

export async function getInterfaces(siteId, nodeId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/node_interfaces/`, config({ token, queryParams: { node: nodeId } }))
}

export async function addInterfaces(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/node_interfaces/`, data, config({ token }))
}

export async function updateInterfaces(siteId, interfaceId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/asset/sites/${siteId}/node_interfaces/${interfaceId}/`, data, config({ token }))
}

export async function removeInterfaces(siteId, interfaceId, token) {
  return await axios.delete(`${window.BACKEND_URL}/asset/sites/${siteId}/node_interfaces/${interfaceId}/`, config({ token }))
}

export async function getNetworks(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/networks/`, config({ token }))
}

export async function getAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/allvalues`, config({ token }))
}

export async function getCarelAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/carelnodes/allvalues`, config({ token }))
}

export async function getSMAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/smnodes/allvalues`, config({ token }))
}

export async function exportNodes(siteId, nodeType, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/${siteId}/${nodeType}/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function exportTemplate(nodeType, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/0/${nodeType}/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importNodes(siteId, nodeType, file, token) {
  let formData = new FormData()
  formData.append("file", file)
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/${nodeType}/bulk/import`, formData, config({ token, file: true }), { signal: new AbortController() })
}
