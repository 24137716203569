import axios from "axios"

const header = token => ({
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Authorization": `Bearer ${token}`
  }
})

export async function retrieveList(siteId, spaceConstraintId, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/sites/${siteId}/constraints/${spaceConstraintId}/constraints_details/`, header(token))
}

export async function retrieve(siteId, spaceConstraintId, spaceConstraintDetailId, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/sites/${siteId}/constraints/${spaceConstraintId}/constraints_details/${spaceConstraintDetailId}/`, header(token))
}

export async function add(siteId, spaceConstraintId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/operation/sites/${siteId}/constraints/${spaceConstraintId}/constraints_details/`, data, header(token))
}

export async function update(siteId, spaceConstraintId, spaceConstraintDetailId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/operation/sites/${siteId}/constraints/${spaceConstraintId}/constraints_details/${spaceConstraintDetailId}/`, data, header(token))
}

export async function remove(siteId, spaceConstraintId, spaceConstraintDetailId, token) {
  return await axios.delete(`${window.BACKEND_URL}/operation/sites/${siteId}/constraints/${spaceConstraintId}/constraints_details/${spaceConstraintDetailId}/`, header(token))
}
