import SpaceConstraintRoutes from "/apps/operation/space-constraint/routes"
import UnavailabilityCategoryRoutes from "/apps/operation/unavailability-category/routes"
import UnavailabilityPeriodRoutes from "/apps/operation/unavailability-period/routes"
import { organization } from "/utils/store/organization"
import { TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Route, Routes, useLocation, useParams } from "react-router-dom"

export default function OperationRoutes() {
  const organizationStore = useSelector(organization)
  const location = useLocation()
  const { t } = useTranslation(["assets"])
  const { siteId } = useParams()
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    setTabs([
      {
        name: t("Space constraints"),
        active: location.pathname.includes("constraints"),
        url: `/organizations/${organizationStore.id}/sites/${siteId}/operations/space-constraints/`
      },
      {
        name: t("Unavailability categories"),
        active: location.pathname.includes("unavailability-categories"),
        url: `/organizations/${organizationStore.id}/sites/${siteId}/operations/unavailability-categories/`
      },
      {
        name: t("Unavailability periods"),
        active: location.pathname.includes("unavailability-periods"),
        url: `/organizations/${organizationStore.id}/sites/${siteId}/operations/unavailability-periods/`
      }
    ])
  }, [location])

  return (
    <Routes>
      <Route
        element={
          <TabsLayout
            title={t("Operations")}
            tabs={tabs}
            setTabs={setTabs}
          />
        }
      >
        <Route
          index
          path="space-constraints/*"
          element={<SpaceConstraintRoutes />}
        />
        <Route
          index
          path="unavailability-categories/*"
          element={<UnavailabilityCategoryRoutes />}
        />
        <Route
          index
          path="unavailability-periods/*"
          element={<UnavailabilityPeriodRoutes />}
        />
      </Route>
    </Routes>
  )
}
