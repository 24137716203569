import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"

import { commonsFields, commonsOverview } from "./commons"

export const Carel = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      id_var: {
        label: i18n.t("ID Var", { ns: "assets" }),
        field: InputText,
        options: { required: true }
      },
      write: {
        label: i18n.t("Write", { ns: "assets" }),
        field: InputSwitch,
        options: {}
      },
      is_logic: {
        label: i18n.t("Is Logic", { ns: "assets" }),
        field: InputSwitch,
        options: {}
      },
      carel_type: {
        label: i18n.t("Type", { ns: "assets" }),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "1", label: i18n.t("Digital (1)") },
            { value: "2", label: i18n.t("Analogue (2)") },
            { value: "3", label: i18n.t("Integer (3)") },
            { value: "4", label: i18n.t("Alarm (4)") }
          ]
        }
      }
    }
  }
}

export const CarelOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      id_var: { label: i18n.t("ID Var", { ns: "assets" }) },
      read_write: { label: i18n.t("Read / Write", { ns: "assets" }) },
      type: { label: i18n.t("Type", { ns: "assets" }) }
    }
  }
}
