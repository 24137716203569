{
  "{{count}} sites_count_one": "{{count}} site",
  "{{count}} sites_count_many": "{{count}} sites",
  "{{count}} sites_count_other": "{{count}} sites",
  "Cancel": "Annuler",
  "Cards": "Cartes",
  "Create": "Créer",
  "Create organization": "Créer une organisation",
  "List": "Liste",
  "Logo (SVG)": "Logo (SVG)",
  "Name": "Nom",
  "Number of sites": "Nombre de sites",
  "Open": "Ouvrir",
  "Organizations": "Organisations",
  "Remove": "Supprimer",
  "Save": "Sauvegarder",
  "Site": "Site",
  "The organization has been created": "L'organisation a été créée.",
  "The organization has been updated": "L'organisation a été mise à jour."
}
