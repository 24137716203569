import { StateRoutesContext } from "/apps/optimization/configuration/state/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["assets"])
  const { state } = useContext(StateRoutesContext)

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      state &&
      state.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Optimization"), url: `/sites/${siteStore.id}/optimization/` },
          { label: t("States"), url: `/sites/${siteStore.id}/optimization/configuration/states/` },
          { label: state.name, url: `/sites/${siteStore.id}/optimization/configuration/states/${state.id}/` },
          { label: t("Delete"), url: `/sites/${siteStore.id}/optimization/configuration/states/${state.id}/delete/` }
        ])
      )
  }, [siteStore.id, state])

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    await remove(siteStore.id, state.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The state has been removed"))])
        navigate(-2)
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  return (
    <>
      {state && (
        <DeleteConfirmation
          objectType={t("State")}
          objectNames={[state.name]}
          remove={removeObject}
          goBack={() => navigate(-1)}
        />
      )}
    </>
  )
}
