import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(`${window.BACKEND_URL}/energy/sites/${siteId}/contracts/`, config({ token, queryParams }))
}

export async function retrieve(siteId, contractId, token) {
  return await axios.get(`${window.BACKEND_URL}/energy/sites/${siteId}/contracts/${contractId}/`, config({ token }))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/energy/sites/${siteId}/contracts/`, data, config({ token }))
}

export async function update(siteId, contractId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/energy/sites/${siteId}/contracts/${contractId}/`, data, config({ token }))
}

export async function remove(siteId, contractId, token) {
  return await axios.delete(`${window.BACKEND_URL}/energy/sites/${siteId}/contracts/${contractId}/`, config({ token }))
}

export async function getGateways(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/`, config({ token }))
}

export async function getEnergyCompanies(token) {
  return await axios.get(`${window.BACKEND_URL}/energy/energy_companies/`, config({ token }))
}

export async function getEnergyDeliveryPoints(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/energy/sites/${siteId}/energy_delivery_points/`, config({ token }))
}

export async function getAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/energy/sites/${siteId}/contracts/allvalues`, config({ token }))
}
