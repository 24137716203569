import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { SpaceConstraintRouteContext } from "../../space-constraint/routes"
import { retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const { spaceConstraint } = useContext(SpaceConstraintRouteContext)

  const retrieve = async () => {
    setLoading(true)
    await retrieveList(siteStore.id, spaceConstraint.id, auth.userData.access_token)
      .then(response => {
        response.data && setItems(response.data)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoading(false))
  }

  const columns = [
    { field: "upper_bound", header: t("Upper bound"), sortable: true },
    { field: "lower_bound", header: t("Lower bound"), sortable: true },
    { field: "exceed_duration", header: t("Exceed duration"), sortable: true },
    { field: "start_validity", header: t("Start validity"), sortable: true },
    { field: "end_validity", header: t("End validity"), sortable: true }
  ]

  const getDetailUrl = objectId => `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraint.id}/details/${objectId}/`

  const getDeleteUrl = objectId => `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraint.id}/details/${objectId}/delete/`

  const onRowClick = objectId => navigate(getDetailUrl(objectId))

  const menuFilters = { columns, items, filters, setFilters }

  useEffect(() => {
    if (siteStore && siteStore.id && spaceConstraint && spaceConstraint.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          {
            label: t("Space constraints"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/`
          },
          {
            label: spaceConstraint.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraint.id}/`
          },
          {
            label: t("Details"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraint.id}/details`
          }
        ])
      )
      retrieve()
    }
  }, [siteStore.id, spaceConstraint.id, organizationStore.id])

  return (
    <section className="list-section">
      <ListActions filters={menuFilters}>
        <Link to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraint.id}/details/create/`}>
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Space constraint details")}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
      />
    </section>
  )
}
