import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["assets"])
  const { siteId, flexId, marketPlayerId } = useParams()

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))

    dispatch(
      updateBreadcrumb([
        { label: t("Sites"), url: "/sites/" },
        { label: t("Detail"), url: `/sites/${siteId}/` },
        { label: t("Optimization"), url: `/sites/${siteId}/optimization/` },
        { label: t("Flexibility"), url: `/sites/${siteId}/optimization/flexibility/` },
        {
          label: t("Market player interaction"),
          url: `/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/`
        },
        {
          label: t("Detail"),
          url: `/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/${marketPlayerId}/`
        },
        {
          label: t("Delete"),
          url: `/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/${marketPlayerId}/delete/`
        }
      ])
    )
  }, [])

  const removeObject = async () => {
    await remove(flexId, marketPlayerId, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The proposal for aggregator has been removed."))])
        navigate(`/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/`)
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
  }

  return (
    <>
      <DeleteConfirmation
        objectType={t("Proposal for aggregator")}
        remove={removeObject}
        goBack={() => navigate(-1)}
      />
    </>
  )
}
