import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { Loader, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getAutomationPoints, getAutomationPointsAllValues, getEquipmentAllValues, getEquipments, getParameters, getStores, update } from "../api"
import { Point, PointOverview } from "../models"
import { PointRouteContext } from "../routes"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [object, setObject] = useState(null)
  const [data, setData] = useState({ time_series_storage_info: {} })
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const { point, retrieveDetail } = useContext(PointRouteContext)
  const [parameters, setParameters] = useState([])
  const [stores, setStores] = useState([])
  const [processing, setProcessing] = useState(false)
  const [loadingParameters, setLoadingParameters] = useState(false)
  const [loadingStores, setLoadingStores] = useState(false)

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await update(siteStore.id, point.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The point has been updated."))]))
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        console.log(error.response.data)
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  const retrieveEquipments = async (queryParams = {}) => {
    return await getEquipments(siteStore.id, auth.userData.access_token, queryParams)
  }

  const getEquipmentValues = async () => {
    return await getEquipmentAllValues(siteStore.id, auth.userData.access_token)
  }

  const retrieveParameters = async () => {
    setLoadingParameters(true)
    await getParameters(auth.userData.access_token)
      .then(response => {
        if (response.data.results) {
          setParameters(response.data.results)
        }
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoadingParameters(false))
  }

  const retrieveAutomationPoints = async (queryParams = {}) => {
    return await getAutomationPoints(siteStore.id, auth.userData.access_token, { ...queryParams, node_equipment: data.equipment })
  }

  const getAutomationPointValues = async () => {
    return await getAutomationPointsAllValues(siteStore.id, auth.userData.access_token)
  }

  const retrieveStores = async () => {
    setLoadingStores(true)
    await getStores(siteStore.id, data.time_series_storage_info.store_type, auth.userData.access_token)
      .then(response => {
        if (response.data.results) {
          setStores(response.data.results)
        }
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoadingStores(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (point) {
      setLoading(false)
      setObject({
        ...point,
        ...{
          time_series_storage_info: point.time_series_storage_info
            ? point.time_series_storage_info
            : {
                acceptable_values: [
                  {
                    original_point_value: 0,
                    gateway_point_value: 0,
                    gateway_point_text: "",
                    gateway_point_value_change: false
                  },
                  {
                    original_point_value: 1,
                    gateway_point_value: 1,
                    gateway_point_text: "",
                    gateway_point_value_change: false
                  }
                ]
              },
          read: point.read === "Yes",
          write: point.write === "Yes"
        }
      })
    }
  }, [point])

  useEffect(() => {
    if (edit) {
      retrieveParameters()
    }
  }, [edit])

  useEffect(() => {
    siteStore.id &&
      point &&
      point.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Building"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/`
          },
          {
            label: t("Points"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/`
          },
          {
            label: point.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/${point.id}/`
          }
        ])
      )
  }, [siteStore.id, point])

  useEffect(() => {
    data.time_series_storage_info.store_type && retrieveStores()
  }, [data.time_series_storage_info.store_type])

  return (
    <>
      {object ? (
        <>
          <div className="base-content-title">
            <h2>{object.name}</h2>
          </div>
          <OverView
            object={object}
            model={Point(retrieveEquipments, getEquipmentValues, object.equipment_name, retrieveAutomationPoints, getAutomationPointValues, object.automation_point_name, parameters, stores)}
            overviewModel={PointOverview()}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            loading={loading}
            processing={processing || loadingParameters || loadingStores}
            setEdit={setEdit}
            deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/${object.id}/delete/`}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}
