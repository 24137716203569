import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { add } from "../api"
import { StateForm } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const { siteId } = useParams()

  const [data, setData] = useState({ active: false, state_values: [] })
  const [errors, setErrors] = useState({})
  const [gateways, setGateways] = useState([])

  const create = async function (e) {
    e.preventDefault()

    await add(siteId, data, auth.userData.access_token)
      .then(response => {
        dispatch(addNotifications([successNotification("Create", t("The state has been created."))]))
        navigate(`/sites/${siteId}/optimization/configuration/states/${response.data.id}`)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))

    dispatch(
      updateBreadcrumb([
        { label: t("Sites"), url: "/sites/" },
        { label: t("Detail"), url: `/sites/${siteId}/` },
        { label: t("Optimization"), url: `/sites/${siteId}/optimization/` },
        { label: t("States"), url: `/sites/${siteId}/optimization/configuration/states/` },
        { label: t("Create"), url: `/sites/${siteId}/optimization/configuration/states/create/` }
      ])
    )

    const getGW = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`connectivity/sites/${siteId}/gateways/`, auth.userData.access_token)()
        .then(results => {
          setGateways(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getGateways(siteId, auth.userData.access_token).then(response => {
        setGateways(response.data.results)
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    getGW()
  }, [])

  return (
    <>
      {data && (
        <>
          <div className="base-content-title">
            <h2>{t("Create state")}</h2>
          </div>

          <form
            className="form-view"
            onSubmit={create}
          >
            <FormContent
              model={StateForm(gateways)}
              data={data}
              setData={setData}
              errors={errors}
            />
            <div className="action-buttons-wrapper">
              <Button
                rounded
                severity="success"
                label={t("Save")}
                type="submit"
                icon="fa-solid fa-floppy-disk"
              />
            </div>
          </form>
        </>
      )}
    </>
  )
}
