import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import { retrieve } from "./api"
import Create from "./components/Create"
import Delete from "./components/Delete"
import DeleteBulk from "./components/DeleteBulk"
import Detail from "./components/Detail"
import List from "./components/List"

const PointRouteContext = createContext(null)

const PointContent = () => {
  const [point, setPoint] = useState()
  const auth = useAuth()
  const { siteId, pointId } = useParams()
  const dispatch = useDispatch()

  const retrieveDetail = async () => {
    await retrieve(siteId, pointId, auth.userData.access_token)
      .then(response => {
        setPoint(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    pointId && retrieveDetail()
  }, [pointId])

  return <PointRouteContext.Provider value={{ point, retrieveDetail }}>{point ? <Outlet /> : <Loader />}</PointRouteContext.Provider>
}

export { PointRouteContext }
export default function PointRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path="multi-delete"
        element={<DeleteBulk />}
      />
      <Route
        path=":pointId"
        element={<PointContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
