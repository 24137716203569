import { retrieve } from "/apps/optimization/configuration/state/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"

import List from "./components/List"

const StateRoutesContext = createContext()

const StateContent = () => {
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { stateId } = useParams()
  const [state, setState] = useState()

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, stateId, auth.userData.access_token)
      .then(response => {
        setState(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    siteStore.id && retrieveDetail()
  }, [siteStore.id])

  return (
    <StateRoutesContext.Provider value={{ state, retrieveDetail }}>
      <Outlet />
    </StateRoutesContext.Provider>
  )
}

export { StateRoutesContext }
export default function StateRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":stateId"
        element={<StateContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
