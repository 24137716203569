import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const SpaceConstraint = (spaces, loadingSpaces, edit = false) => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: i18n.t("Name", { ns: "operation" }), field: InputText, options: { required: true, maxLength: 255 } },
    description: {
      label: i18n.t("Description", { ns: "operation" }),
      field: InputTextarea,
      options: { required: true }
    },
    type: {
      label: i18n.t("Type", { ns: "operation" }),
      field: Dropdown,
      options: {
        options: [
          { value: "Contract", label: i18n.t("Contract", { ns: "operation" }) },
          { value: "AlertInternal", label: i18n.t("Alert internal", { ns: "operation" }) },
          { value: "AlertExternal", label: i18n.t("Alert external", { ns: "operation" }) }
        ],
        required: true
      }
    },
    parameter: {
      label: i18n.t("Parameter", { ns: "operation" }),
      field: Dropdown,
      options: {
        options: [
          { value: "Temperature (°C)", label: i18n.t("Temperature (°C)", { ns: "operation" }) },
          { value: "Humidity (%)", label: i18n.t("Humidity (%)", { ns: "operation" }) },
          { value: "CO2 Concentration (ppm)", label: i18n.t("CO2 Concentration (ppm)", { ns: "operation" }) }
        ],
        required: true
      }
    },
    space_id: {
      label: i18n.t("Space", { ns: "operation" }),
      field: Dropdown,
      options: {
        options: spaces,
        optionLabel: "name",
        optionValue: "id",
        disabled: edit,
        loading: loadingSpaces,
        placeholder: loadingSpaces ? "Loading..." : "Select space",
        required: true
      }
    }
  }
}
