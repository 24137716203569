import { DateInput, TimeInput } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"

export const ConfirmedCurtailSchedule = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    curtail_start_date: {
      label: i18n.t("Start date", { ns: "optimization" }),
      field: DateInput,
      options: { required: true }
    },
    curtail_start_time: {
      label: i18n.t("Start time", { ns: "optimization" }),
      field: TimeInput,
      options: { required: true }
    },
    curtail_end_date: {
      label: i18n.t("End date", { ns: "optimization" }),
      field: DateInput,
      options: { required: true }
    },
    curtail_end_time: {
      label: i18n.t("End time", { ns: "optimization" }),
      field: TimeInput,
      options: { required: true }
    },
    pre_cooling_start_date: {
      label: i18n.t("Pre-cooling start date", { ns: "optimization" }),
      field: DateInput,
      options: { required: true }
    },
    pre_cooling_start_time: {
      label: i18n.t("Pre-cooling start time", { ns: "optimization" }),
      field: TimeInput,
      options: { required: true }
    },
    post_cooling_end_date: {
      label: i18n.t("Post-cooling end date", { ns: "optimization" }),
      field: DateInput,
      options: { required: true }
    },
    post_cooling_end_time: {
      label: i18n.t("Post-cooling end time", { ns: "optimization" }),
      field: TimeInput,
      options: { required: true }
    },
    curtail_capacity: {
      label: i18n.t("Curtail capacity", { ns: "optimization" }),
      field: InputNumber,
      options: { required: true, showButtons: true }
    },
    execution_desired: {
      label: i18n.t("Execution desired", { ns: "optimization" }),
      field: InputSwitch
    },
    test: {
      label: i18n.t("Test Schedule", { ns: "optimization" }),
      field: InputSwitch
    }
  }
}
