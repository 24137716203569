import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(token, queryParams) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/hardwares/`, config({ token, queryParams }))
}

export async function retrieve(id, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/hardwares/${id}`, config({ token }))
}

export async function add(data, token) {
  return await axios.post(`${window.BACKEND_URL}/connectivity/hardwares/`, data, config({ token }))
}

export async function update(id, data, token) {
  return await axios.put(`${window.BACKEND_URL}/connectivity/hardwares/${id}/`, data, config({ token }))
}

export async function remove(id, token) {
  return await axios.delete(`${window.BACKEND_URL}/connectivity/hardwares/${id}/`, config({ token }))
}

export async function getCount(id, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/hardwares/${id}/count`, config({ token }))
}

export async function getAllValues(token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/hardwares/allvalues`, config({ token }))
}
