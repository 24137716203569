import { paginatedRequestFlex } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { ListActions, ListLayout, Loader, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { updateCurtailParameters } from "../api"
import { CurtailParameters } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])

  const [object, setObject] = useState(null)
  const { siteId, flexId } = useParams()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [historyParameters, setHistoryParameters] = useState([])
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)

  const retrieveDetail = async () => {
    // TODO: remove when the api will no longer paginate
    await paginatedRequestFlex(`flex/${flexId}/constant_load_curtail_parameters/`, auth.userData.access_token)()
      .then(results => {
        if (results.length > 0) {
          setObject(results[0])
          setData(results[0])
        }
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })

    // TODO: uncomment when the API will no longer paginate
    /* await getCurtailParameters(flexId, auth.userData.access_token).then(response => {
      if(response.data && response.data.count > 0) {
        setObject(response.data.results[0])
        setData(response.data.results[0])
      }
      setLoading(false)
    }).catch(error => {
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const updateObject = async function (e) {
    e.preventDefault()
    await updateCurtailParameters(flexId, data.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("Curtail parameters have been updated."))]))
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))

    dispatch(
      updateBreadcrumb([
        { label: t("Sites"), url: "/sites/" },
        { label: t("Detail"), url: `/sites/${siteId}/` },
        { label: t("Optimization"), url: `/sites/${siteId}/optimization/` },
        { label: t("Flexibility"), url: `/sites/${siteId}/optimization/flexibility/` },
        {
          label: t("Curtail parameters"),
          url: `/sites/${siteId}/optimization/flexibility/${flexId}/load-curtail-parameters/`
        }
      ])
    )

    retrieveDetail()
  }, [flexId])

  useEffect(() => {
    const getHCP = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequestFlex(`flex/${flexId}/constant_load_curtail_parameters_history/${object.id}/`, auth.userData.access_token)()
        .then(results => {
          setHistoryParameters(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* getHistory(flexId, object.id, auth.userData.access_token).then(response => {
        setHistoryParameters(response.data.results)
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }
    if (object && object.id && !edit) {
      getHCP()
    }
  }, [object, edit])

  return (
    <>
      {loading ? (
        object ? (
          <>
            <OverView
              object={object}
              model={CurtailParameters()}
              updateObject={updateObject}
              data={data}
              setData={setData}
              errors={errors}
              edit={edit}
              setEdit={setEdit}
              deleteUrl={`/sites/${siteId}/optimization/flexibility/${flexId}/load-curtail-parameters/${object.id}/delete/`}
            />
            <section className="list-section">
              <div className="list-section-head">
                <h3>{t("History")}</h3>
              </div>
              <ListLayout
                columns={[
                  { field: "update_by", header: t("Updated by"), sortable: true },
                  {
                    field: "update_time",
                    header: t("Updated time"),
                    processValue: value => {
                      return new Date(value).toLocaleString()
                    }
                  },
                  { field: "comment", header: t("Comment") },
                  { field: "type", header: t("Type"), sortable: true }
                ]}
                value={historyParameters}
                actionsBodyTemplate={() => <></>}
              />
            </section>
          </>
        ) : (
          <section className="list-section">
            <ListActions>
              <Link to={`/sites/${siteId}/optimization/flexibility/${flexId}/load-curtail-parameters/create/`}>
                <Button
                  rounded
                  severity="info"
                  icon="fa-regular fa-plus"
                  label={t("Create curtail parameters")}
                />
              </Link>
            </ListActions>
          </section>
        )
      ) : (
        <Loader />
      )}
    </>
  )
}
