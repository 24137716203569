import { retrieve } from "/apps/assets/building/equipment/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import DeleteBulk from "./components/DeleteBulk"
import Detail from "./components/Detail"
import List from "./components/List"

const EquipmentRoutesContext = createContext(null)

const EquipmentContent = () => {
  const auth = useAuth()
  const { siteId, equipmentId } = useParams()
  const dispatch = useDispatch()
  const [equipment, setEquipment] = useState()

  const retrieveDetail = async () => {
    await retrieve(siteId, equipmentId, auth.userData.access_token)
      .then(response => {
        setEquipment(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    equipmentId && retrieveDetail()
  }, [equipmentId])

  return <EquipmentRoutesContext.Provider value={{ equipment, retrieveDetail }}>{equipment ? <Outlet /> : <Loader />}</EquipmentRoutesContext.Provider>
}

export { EquipmentRoutesContext }
export default function EquipmentRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path="multi-delete"
        element={<DeleteBulk />}
      />
      <Route
        path=":equipmentId"
        element={<EquipmentContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
