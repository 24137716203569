const buildQueryParams = (limit, offset, filters = {}, sortField = null, sortOrder = null) => {
  let queryParams = `?limit=${limit}&offset=${offset}`
  Object.keys(filters).forEach(key => {
    Object.values(filters[key]).forEach(value => {
      queryParams = queryParams + `&${key}=${value}`
    })
  })
  if (sortField && sortOrder) {
    queryParams = queryParams + `&ordering=${sortOrder > 0 ? "" : "-"}${sortField}`
  }
  return queryParams
}

export { buildQueryParams }
