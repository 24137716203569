import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/connector_instances/`, config({ token, queryParams }))
}

export async function retrieve(siteId, connectorInstanceId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/connector_instances/${connectorInstanceId}/`, config({ token }))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/connectivity/sites/${siteId}/connector_instances/`, data, config({ token }))
}

export async function update(siteId, connectorInstanceId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/connectivity/sites/${siteId}/connector_instances/${connectorInstanceId}/`, data, config({ token }))
}

export async function remove(siteId, connectorInstanceId, token) {
  return await axios.delete(`${window.BACKEND_URL}/connectivity/sites/${siteId}/connector_instances/${connectorInstanceId}/`, config({ token }))
}

export async function getCount(connectorInstanceId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/connector_instances/${connectorInstanceId}/count/`, config({ token }))
}

export async function getConnectorInstancesCount(ids, token) {
  return await axios.post(`${window.BACKEND_URL}/connectivity/connector_instances/count/`, ids, config({ token }))
}

export async function removeConnectorInstances(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/connectivity/sites/${siteId}/connector_instances/deletion/`, ids, config({ token }))
}

export async function getAllValues(gatewayId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/gateway/${gatewayId}/connector-instances/allvalues`, config({ token }))
}
