import axios from "axios"

const header = token => ({
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Authorization": `Bearer ${token}`
  }
})

/**
 * @todo Update requests when paginations ready
 */

export const retrieveAll = async (siteId, token) => await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breakers/`, header(token))
export const retrieveList = async (siteId, queryParams, token) => await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breakers/${queryParams}`, header(token))
export const retrieve = async (id, token) => await axios.get(`${window.BACKEND_URL}/DETAIL_URL/${id}/`, header(token))
export const add = async (siteId, data, token) => await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/circuit_breakers/`, data, header(token))
export const update = async (id, data, token) => await axios.put(`${window.BACKEND_URL}/UPDATE_URL/${id}/`, data, header(token))
export const remove = async (id, token) => await axios.delete(`${window.BACKEND_URL}/DELETE_URL/${id}/`, header(token))
export const getCount = async (id, token) => await axios.get(`${window.BACKEND_URL}/DETAIL_URL/${id}/count`, header(token))
