import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(token, queryParams) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/`, config({ token, queryParams }))
}

export async function retrieve(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/`, config({ token }))
}

export async function add(data, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/`, data, config({ token }))
}

export async function update(siteId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/asset/sites/${siteId}/`, data, config({ token }))
}

export async function remove(siteId, token) {
  return await axios.delete(`${window.BACKEND_URL}/asset/sites/${siteId}/`, config({ token }))
}

export async function getCount(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/count`, config({ token }))
}

export async function getAllValues(organizationId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/site/allvalues?organization_id=${organizationId}`, config({ token }))
}

export async function getPriceZones(token) {
  return await axios.get(`${window.BACKEND_URL}/asset/price_zones/`, config({ token }))
}

export async function getWeatherZones(countryCode, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/weather_zones/${countryCode}/?ordering=city_name`, config({ token }))
}

export async function getClients(token) {
  return await axios.get(`${window.BACKEND_URL}/asset/clients/`, config({ token }))
}

export async function getContracts(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/contracts/?site_id=${siteId}`, config({ token }))
}

export async function addContract(contract, token) {
  return await axios.post(`${window.BACKEND_URL}/operation/contracts/`, contract, config({ token }))
}

export async function updateContract(contractId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/operation/contracts/${contractId}`, data, config({ token }))
}

export async function getSavingShares(contractId, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/savings_share?contract_id=${contractId}`, config({ token }))
}

export async function addSavingShare(savingShare, token) {
  return await axios.post(`${window.BACKEND_URL}/operation/savings_share`, savingShare, config({ token }))
}

export async function updateSavingShare(savingShareId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/operation/savings_shares/${savingShareId}`, data, config({ token }))
}
