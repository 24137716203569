{
  "Alarm (4)": "Alarm (4)",
  "Analogue (2)": "Analogue (2)",
  "Analogue Input (0)": "Analogue Input (0)",
  "Analogue Output (1)": "Analogue Output (1)",
  "Analogue Value (2)": "Analogue Value (2)",
  "Binary Input (3)": "Binary Input (3)",
  "Binary Output (4)": "Binary Output (4)",
  "Binary Value (5)": "Binary Value (5)",
  "Data type": "Data type",
  "Digital (1)": "Digital (1)",
  "Distributor": "Distributor",
  "Gateway": "Gateway",
  "Home": "Home",
  "Integer (3)": "Integer (3)",
  "Multi State Input (13)": "Multi State Input (13)",
  "Multi State Output (14)": "Multi State Output (14)",
  "No weather zone found": "No weather zone found.",
  "Sign out": "Sign out",
  "Surface area (m²)": "Surface area (m²)",
  "Type": "Type"
}
