import { SelectArray } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { commonsFields, commonsOverview } from "./commons"

export const SMControllerNode = (spaces, retrieveSMNodes, getAllValues, label_value = "") => {
  return {
    ...commonsFields(spaces),
    ...{
      sm_node: {
        label: i18n.t("System manager", { ns: "assets" }),
        field: SelectArray,
        options: {
          getItems: retrieveSMNodes,
          getAllValues: getAllValues,
          columns: [
            { field: "name", header: i18n.t("Name", { ns: "assets" }) },
            { field: "model", header: i18n.t("Model", { ns: "assets" }) },
            { field: "manufacturer", header: i18n.t("Manufacturer", { ns: "assets" }) },
            { field: "proto", header: i18n.t("Protocol", { ns: "assets" }) },
            { field: "sm_type", header: i18n.t("SM Type", { ns: "assets" }) },
            { field: "software", header: i18n.t("Software", { ns: "assets" }) },
            { field: "user", header: i18n.t("User", { ns: "assets" }) }
          ],
          columnsFilter: [
            { field: "name", header: i18n.t("Name", { ns: "assets" }) },
            { field: "model", header: i18n.t("Model", { ns: "assets" }) },
            { field: "manufacturer", header: i18n.t("Manufacturer", { ns: "assets" }) },
            { field: "proto", header: i18n.t("Protocol", { ns: "assets" }) },
            { field: "sm_type", header: i18n.t("SM Type", { ns: "assets" }) },
            { field: "software", header: i18n.t("Software", { ns: "assets" }) },
            { field: "user", header: i18n.t("User", { ns: "assets" }) }
          ],
          required: true,
          searchLabel: (id, items) => {
            let find = items.find(node => node.id === id)
            return find ? find.name : ""
          },
          LABEL_VALUE: label_value
        }
      },
      node_addr: {
        label: i18n.t("Node address", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      },
      device_id: {
        label: i18n.t("Device ID", { ns: "assets" }),
        field: InputText,
        options: {
          required: true,
          maxLength: 255
        }
      },
      device_type: {
        label: i18n.t("Device type", { ns: "assets" }),
        field: InputText,
        options: {
          required: true,
          maxLength: 255
        }
      },
      suction_id: {
        label: i18n.t("Suction ID", { ns: "assets" }),
        field: InputNumber,
        options: {
          showButtons: true
        }
      },
      num_suction: {
        label: i18n.t("Num suction", { ns: "assets" }),
        field: InputNumber,
        options: {
          showButtons: true
        }
      },
      multicasename: {
        label: i18n.t("Multi-case name", { ns: "assets" }),
        field: InputNumber,
        options: {
          showButtons: true
        }
      },
      rack_id: {
        label: i18n.t("Rack ID", { ns: "assets" }),
        field: InputNumber,
        options: {
          showButtons: true
        }
      }
    }
  }
}

export const SMControllerOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      sm_node_label: { label: i18n.t("System manager", { ns: "assets" }) },
      node_addr: { label: i18n.t("Node address", { ns: "assets" }) },
      device_id: { label: i18n.t("Device ID", { ns: "assets" }) },
      device_type: { label: i18n.t("Device type", { ns: "assets" }) },
      suction_id: { label: i18n.t("Suction ID", { ns: "assets" }) },
      num_suction: { label: i18n.t("Num suction", { ns: "assets" }) },
      multicasename: { label: i18n.t("Multi-case name", { ns: "assets" }) },
      rack_id: { label: i18n.t("Rack ID", { ns: "assets" }) }
    }
  }
}
