import { organization } from "/utils/store/organization"
import { site } from "/utils/store/site"
import { TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Route, Routes, useLocation } from "react-router-dom"
import EquipmentRoutes from "./equipment/routes"
import PointRoutes from "./point/routes"
import SpaceRoutes from "./space/routes"

export default function BuildingRoutes() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const location = useLocation()
  const { t } = useTranslation(["assets"])
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    setTabs([
      {
        name: t("Spaces"),
        active: location.pathname.includes("spaces"),
        url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/`
      },
      {
        name: t("Equipments"),
        active: location.pathname.includes("equipments"),
        url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/`
      },
      {
        name: t("Points"),
        active: location.pathname.includes("points"),
        url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/`
      }
    ])
  }, [location])

  return (
    <Routes>
      <Route
        element={
          <TabsLayout
            title={t("Building")}
            tabs={tabs}
            setTabs={setTabs}
          />
        }
      >
        <Route
          index
          path="/spaces/*"
          element={<SpaceRoutes />}
        />
        <Route
          index
          path="/equipments/*"
          element={<EquipmentRoutes />}
        />
        <Route
          index
          path="/points/*"
          element={<PointRoutes />}
        />
      </Route>
    </Routes>
  )
}
