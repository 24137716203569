import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const CircuitBreaker = (spaces, circuitBreakers, loadingSpaces, loadingCircuitBreakers) => ({
  /*
   * List of fields for the model, like this
   */
  name: {
    label: i18n.t("Name", { ns: "assets" }),
    field: InputText,
    options: {
      required: true
    }
  },
  description: {
    label: i18n.t("Description", { ns: "assets" }),
    field: InputTextarea,
    options: {
      required: true
    }
  },
  cabinet: {
    label: i18n.t("Cabinet", { ns: "assets" }),
    field: InputText,
    options: {
      required: true
    }
  },
  space_id: {
    label: i18n.t("Space", { ns: "assets" }),
    field: Dropdown,
    options: {
      default: null,
      options: spaces,
      optionLabel: "name",
      optionValue: "id",
      filter: true,
      loading: loadingSpaces,
      placeholder: loadingSpaces ? "Loading..." : "Select space"
    }
  },
  nominal_intensity: {
    label: i18n.t("Nominal intensity", { ns: "assets" }),
    field: InputNumber,
    options: {
      default: null,
      required: true
    }
  },
  unspecified_loads: {
    label: i18n.t("Unspecified loads", { ns: "assets" }),
    field: InputSwitch,
    options: {}
  },
  parent_circuit_breaker_id: {
    label: i18n.t("Parent circuit breaker", { ns: "assets" }),
    field: Dropdown,
    options: {
      value: null,
      options: circuitBreakers,
      optionLabel: "name",
      optionValue: "id",
      filter: true,
      loading: loadingCircuitBreakers,
      placeholder: loadingCircuitBreakers ? "Loading..." : "Select parent curcuit break"
    }
  }
})
