import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { retrieveList, getAllValues } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const COMPANY_TYPE = "Distributor"

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["configuration"])
  const [distributors, setDistributors] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)
  const [sortOrder, setSortOrder] = useState(1)
  const [sortField, setSortField] = useState("name")

  const DEFAULT_FILTER = {
    company_type: [COMPANY_TYPE]
  }

  const retrieve = async (queryParams = { ...DEFAULT_FILTER, limit, offset }) => {
    setLoading(true)
    await retrieveList(auth.userData.access_token, queryParams)
      .then(({ data }) => {
        data.results && setDistributors(data.results)
        setCount(data.count)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "country", header: t("Country"), sortable: true }
  ]

  const columnsFilter = [
    { field: "name", header: t("Name") },
    { field: "country", header: t("Country") }
  ]

  const getDetailUrl = id => {
    return `/configuration/energy-company/distributors/${id}/`
  }

  const getDeleteUrl = id => {
    return `/configuration/energy-company/distributors/${id}/delete/`
  }

  const onRowClick = id => {
    navigate(getDetailUrl(id))
  }

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { label: t("Energy companies"), url: "/configuration/energy-company/distributors/" },
        { label: t("Distributors"), url: "/configuration/energy-company/distributors/" }
      ])
    )
    getFilterValues()
  }, [])

  const queryParams = () => {
    return {
      ...filters,
      ...DEFAULT_FILTER,
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  }

  useEffect(() => {
    offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieve(queryParams())
  }, [filters, sortField, sortOrder])

  useEffect(() => {
    retrieve(queryParams())
  }, [limit, offset])

  return (
    <>
      <ListActions
        filterBackend={true}
        filters={menuFilters}
        loading={loadingFilters}
      >
        <Link to="/configuration/energy-company/distributors/create/">
          <Button
            rounded
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={distributors}
        filters={filters}
        objectName={t("Distributors")}
        loading={loading}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        limit={limit}
        offset={offset}
        setLimit={setLimit}
        setOffset={setOffset}
        count={count}
        onSort={onSort}
        sortOrder={sortOrder}
        sortField={sortField}
      />
    </>
  )
}
