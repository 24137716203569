import * as React from "react"
import { Route, Routes } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"

import List from "./components/List"

export default function MarketPlayerInteractionRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="/create/"
        element={<Create />}
      />
      <Route
        path="/:marketPlayerId/"
        element={<Detail />}
      />
      <Route
        path="/:marketPlayerId/delete/"
        element={<Delete />}
      />
    </Routes>
  )
}
