import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const Network = (spaces, loadingSpaces, currentNetwork = false) => {
  return {
    /*
     * List of fields for the model, like this
     */
    type: {
      label: i18n.t("Type", { ns: "assets" }),
      field: Dropdown,
      options: {
        required: true,
        options: [
          { value: "ipnetwork", label: i18n.t("IP", { ns: "assets" }) },
          { value: "serialnetwork", label: i18n.t("Serial", { ns: "assets" }) }
        ],
        disabled: currentNetwork
      }
    },
    description: {
      label: i18n.t("Description", { ns: "assets" }),
      field: InputTextarea,
      options: {}
    },
    space: {
      label: i18n.t("Space", { ns: "assets" }),
      field: Dropdown,
      options: {
        options: spaces,
        optionLabel: "name",
        optionValue: "id",
        loading: loadingSpaces,
        placeholder: loadingSpaces ? "Loading..." : "Select space",
        filter: true
      }
    },
    ip_address_range: {
      label: i18n.t("IP Range", { ns: "assets" }),
      field: InputText,
      dependsOn: "type",
      dependsOnValue: "ipnetwork",
      helpText: i18n.t("CIDR format: {{format}}", { ns: "assets", format: "192.168.3.56/22" }),
      options: {
        required: true,
        type: "text",
        pattern: "((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))/[0-9]+",
        placeholder: "0.0.0.0/0"
      }
    },
    name: {
      label: i18n.t("Name", { ns: "assets" }),
      field: InputText,
      dependsOn: "type",
      dependsOnValue: "serialnetwork",
      options: {
        required: true,
        maxLength: 255
      }
    },
    is_bacnet: {
      label: i18n.t("Bacnet network", { ns: "assets" }),
      field: InputSwitch,
      options: {}
    },
    bacnet_network_number: {
      label: i18n.t("Bacnet number", { ns: "assets" }),
      field: InputNumber,
      dependsOn: "is_bacnet",
      dependsOnValue: true,
      options: { required: true, showButtons: true }
    }
  }
}

export const IPNetworkOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    type: { label: i18n.t("Type", { ns: "assets" }) },
    description: { label: i18n.t("Description", { ns: "assets" }) },
    space_name: { label: i18n.t("Space", { ns: "assets" }) },
    ip_address_range: { label: i18n.t("IP Range", { ns: "assets" }) },
    is_bacnet: { label: i18n.t("Bacnet network", { ns: "assets" }) },
    bacnet_network_number: { label: i18n.t("Bacnet number", { ns: "assets" }) }
  }
}

export const SerialNetworkOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    type: { label: i18n.t("Type", { ns: "assets" }) },
    description: { label: i18n.t("Description", { ns: "assets" }) },
    space_name: { label: i18n.t("Space", { ns: "assets" }) },
    name: { label: i18n.t("Name", { ns: "assets" }) },
    is_bacnet: { label: i18n.t("Bacnet network", { ns: "assets" }) },
    bacnet_network_number: { label: i18n.t("Bacnet number", { ns: "assets" }) }
  }
}
