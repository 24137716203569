import { DatetimeInput } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"

export const SpaceConstraintDetail = () => {
  /*
   * List of fields for the model, like this
   */
  return {
    upper_bound: {
      label: i18n.t("Upper bound", { ns: "operation" }),
      field: InputNumber,
      options: { showButtons: true }
    },
    lower_bound: {
      label: i18n.t("Lower bound", { ns: "operation" }),
      field: InputNumber,
      options: { showButtons: true }
    },
    exceed_duration: {
      label: i18n.t("Exceed duration", { ns: "operation" }),
      field: InputNumber,
      options: { showButtons: true }
    },
    start_validity: {
      label: i18n.t("Start validity", { ns: "operation" }),
      field: DatetimeInput,
      options: { required: false }
    },
    end_validity: {
      label: i18n.t("End validity", { ns: "operation" }),
      field: DatetimeInput,
      options: { required: false }
    }
  }
}
