import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation, Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { GatewayRoutesContext } from "../../gateway/routes"
import { getConnectorInstancesCount, removeConnectorInstances } from "../api"

const DeleteBulk = () => {
  const auth = useAuth()
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { t } = useTranslation(["assets"])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [objectCount, setObjectCount] = useState()
  const [loadingRelatedObject, setLoadingRelatedObject] = useState(false)
  const [processing, setProcessing] = useState(false)
  const ids = searchParams.getAll("id")
  const { gateway } = useContext(GatewayRoutesContext)

  const count = async () => {
    setLoadingRelatedObject(true)
    await getConnectorInstancesCount(ids, auth.userData.access_token)
      .then(({ data, status, statusText }) => {
        if (status === 200) {
          setObjectCount(data)
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Connector instances", message)]))
      })
      .finally(() => setLoadingRelatedObject(false))
  }

  const bulkDelete = async () => {
    setProcessing(true)
    await removeConnectorInstances(siteStore.id, ids, auth.userData.access_token)
      .then(({ status, statusText }) => {
        if (status === 200) {
          dispatch(addNotifications([successNotification("Connector instances", t("Connector instances has been removed."))]))
          navigate(`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/`)
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setProcessing(false))
  }

  useLayoutEffect(() => {
    count()
  }, [])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Gateways"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/`
          },
          {
            label: gateway.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/`
          },
          {
            label: t("Connector instances"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/`
          },
          {
            label: t("Delete"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/multi-delete/?${searchParams}`
          }
        ])
      )
  }, [siteStore.id, organizationStore.id, gateway])

  return loadingRelatedObject ? (
    <Loader />
  ) : (
    objectCount && objectCount.label && !!objectCount.label.length && (
      <DeleteConfirmation
        objectType={t("Connector instances")}
        objectNames={objectCount.label}
        relatedObjects={objectCount}
        remove={bulkDelete}
        processing={processing}
        goBack={() => navigate(-1)}
      />
    )
  )
}

export default DeleteBulk
