import Create from "/apps/configuration/edge-block-template/components/Create"
import Delete from "/apps/configuration/edge-block-template/components/Delete"
import Detail from "/apps/configuration/edge-block-template/components/Detail"
import List from "/apps/configuration/edge-block-template/components/List"
import * as React from "react"
import { Route, Routes } from "react-router-dom"

export default function EdgeBlockTemplateRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":id"
        element={<Detail />}
      />
      <Route
        path=":id/delete/"
        element={<Delete />}
      />
    </Routes>
  )
}
