import * as React from "react"
import { createContext, useState } from "react"
import { Outlet, Route, Routes } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"
import List from "./components/List"

const RouteContext = createContext(null)

const Content = () => {
  const [objectDetail, setObjectDetail] = useState()
  const retrieveDetail = () => {
    setObjectDetail(null)
  }

  return (
    <RouteContext.Provider value={{ objectDetail, retrieveDetail }}>
      <Outlet />
    </RouteContext.Provider>
  )
}

const CircuitBreakerRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":id"
        element={<Content />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}

export { RouteContext }
export default CircuitBreakerRoutes
