import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const GatewayBox = () => {
  return {
    manufacturer: { label: i18n.t("Manufacturer", { ns: "connectivity" }), field: InputText, options: { maxLength: 255 } },
    model: { label: i18n.t("Model", { ns: "connectivity" }), field: InputText, options: { maxLength: 255 } },
    serial_id: {
      label: i18n.t("Serial ID", { ns: "connectivity" }),
      field: InputText,
      options: { required: true, keyfilter: /[\w\d_\-]/ }
    },
    description: { label: i18n.t("Description", { ns: "connectivity" }), field: InputTextarea }
  }
}
