import i18n from "i18next"
import { InputText } from "primereact/inputtext"

export const Organization = () => {
  return {
    name: {
      label: i18n.t("Name", { ns: "organization" }),
      field: InputText,
      options: { required: true, maxLength: 255 }
    }
  }
}
