import { DateInput, Dropdown, TimeInput } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputTextarea } from "primereact/inputtextarea"

export const UnavailabilityPeriod = (unavailabilityCategories, loadingUnavailabilityCategories) => ({
  /*
   * List of fields for the model, like this
   */
  unavailability_category_id: {
    label: i18n.t("Unavailability category", { ns: "operation" }),
    field: Dropdown,
    options: {
      required: true,
      options: unavailabilityCategories,
      optionLabel: "name",
      optionValue: "id",
      loading: loadingUnavailabilityCategories,
      placeholder: loadingUnavailabilityCategories ? "Loading..." : "Select unavailability category"
    }
  },
  comment: {
    label: i18n.t("Comment", { ns: "operation" }),
    field: InputTextarea,
    options: { required: true }
  },
  start_date: {
    intro: i18n.t("Dates must be provided in local site timezone", { ns: "operation" }),
    label: i18n.t("Start date", { ns: "operation" }),
    field: DateInput,
    options: { required: true }
  },
  start_time: {
    label: i18n.t("Start time", { ns: "operation" }),
    field: TimeInput,
    options: { required: true }
  },
  end_date: {
    label: i18n.t("End date", { ns: "operation" }),
    field: DateInput,
    options: {}
  },
  end_time: {
    label: i18n.t("End time", { ns: "operation" }),
    field: TimeInput,
    options: {}
  }
})

export const UnavailabilityPeriodOverview = () => {
  return {
    category_name: { label: i18n.t("Unavailability category", { ns: "operation" }) },
    comment: { label: i18n.t("Comment", { ns: "operation" }) },
    start_date: { label: i18n.t("Start date", { ns: "operation" }) },
    start_time: { label: i18n.t("Start time", { ns: "operation" }) },
    end_date: { label: i18n.t("End date", { ns: "operation" }) },
    end_time: { label: i18n.t("End time", { ns: "operation" }) }
  }
}
