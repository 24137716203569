import axios from "axios"

const header = token => {
  return {
    "Content-Type": "application/json; charset=UTF-8",
    "Authorization": `Bearer ${token}`
  }
}

function headerFile(token) {
  return {
    "Content-Type": "multipart/form-data; charset=UTF-8",
    "Authorization": `Bearer ${token}`
  }
}

const config = ({ token, queryParams = null, file = false }) => {
  return {
    headers: file ? headerFile(token) : header(token),
    params: queryParams,
    paramsSerializer: { indexes: null }
  }
}

const paginatedRequest = (url, token) => {
  return function paginatedFetcher(next, obj_list = []) {
    return axios.get(next ? next : `${window.BACKEND_URL}/${url}`, config({ token })).then(({ data }) => {
      obj_list.push(...data.results)
      if (!data.next) return obj_list
      return paginatedFetcher(data.next, obj_list)
    })
  }
}

const paginatedRequestFlex = (url, token) => {
  return function paginatedFetcher(next, obj_list = []) {
    return axios.get(next ? next : `${window.BACKEND_FLEX_URL}/${url}`, config({ token })).then(({ data }) => {
      obj_list.push(...data.results)
      if (!data.next) return obj_list
      return paginatedFetcher(data.next, obj_list)
    })
  }
}

export { header, config, paginatedRequest, paginatedRequestFlex }
