import { paginatedRequest } from "/utils/api"
import { PROTOCOL_INSTANCE_TYPE } from "/utils/constants/protocol-instance"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout, Loader, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import { InputNumber } from "primereact/inputnumber"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { GatewayRoutesContext } from "../../../gateway/routes"
import { ConnectorInstanceRoutesContext } from "../../routes"
import { retrieveResponseTimeouts, update, updateResponseTimeouts } from "../api"
import { ProtocolInstance, ProtocolInstanceOverview } from "../models"
import { ProtocolInstanceRouteContext } from "../routes"

const ModbusProtocolTimeouts = ({ siteId, protocolInstance, setModbusTimeoutManagement }) => {
  const { t } = useTranslation(["assets"])
  const dispatch = useDispatch()
  const auth = useAuth()
  const [items, setItems] = useState([])
  const [filters, setFilters] = useState({})
  const [loading, setLoading] = useState(false)
  const [processTimeouts, setProcessTimeouts] = useState(false)

  const update = (value, nodeId) => {
    setItems(items.map(item => (item.node === nodeId ? { ...item, response_timeout: value } : item)))
  }

  useEffect(() => {
    const getResponseTimeouts = async () => {
      setLoading(true)
      await retrieveResponseTimeouts(siteId, protocolInstance.id, auth.userData.access_token)
        .then(response => {
          setItems(response.data)
        })
        .catch(error => {
          const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
          dispatch(addNotifications([errorNotification("Protocole instance", message)]))
        })
        .finally(() => setLoading(false))
    }

    getResponseTimeouts()
  }, [protocolInstance])

  const responseTimeoutBody = rowData => (
    <InputNumber
      value={rowData.response_timeout}
      onChange={e => update(e.value, rowData.node)}
      showButtons
    />
  )

  const updateTimeouts = async () => {
    setProcessTimeouts(true)
    await updateResponseTimeouts(siteId, protocolInstance.id, items, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Protocole instance", t("Response timeouts has been updated"))]))
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => {
        setProcessTimeouts(false)
      })
  }

  const columns = [
    { field: "label", header: t("Node") },
    { field: "response_timeout", header: t("Response timeout"), body: responseTimeoutBody }
  ]

  const cancelManagement = () => {
    setModbusTimeoutManagement(false)
  }

  return (
    <>
      <div className="base-content-title">
        <h2>{t("Manage modbus response timeouts")}</h2>
      </div>
      <section className="list-section">
        <ListActions filters={{ items, columns, filters, setFilters }}>
          <Button
            rounded
            onClick={updateTimeouts}
            severity="success"
            icon={processTimeouts ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-arrows-rotate"}
            disabled={processTimeouts}
            label={t("Update response timeouts")}
          />
          <Button
            rounded
            onClick={cancelManagement}
            severity="info"
            icon="fa-solid fa-xmark"
            label={t("Cancel")}
          />
        </ListActions>
        <ListLayout
          dataKey="node"
          value={items}
          columns={columns}
          filters={filters}
          loading={loading}
          hideActions={true}
        />
      </section>
    </>
  )
}

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [object, setObject] = useState(null)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingGateway, setLoadingGateway] = useState(false)
  const { gateway } = useContext(GatewayRoutesContext)
  const { connector } = useContext(ConnectorInstanceRoutesContext)
  const { protocolInstance, retrieveDetail } = useContext(ProtocolInstanceRouteContext)
  const [gatewayInterfaces, setGatewayInterfaces] = useState([])
  const [searchParams] = useSearchParams()
  const editTimeout = searchParams.get("editTimeout")
  const [modbusTimeoutManagement, setModbusTimeoutManagement] = useState(editTimeout && parseInt(editTimeout) === 1)

  useEffect(() => {
    if (protocolInstance) {
      setLoading(false)
      setObject({
        ...protocolInstance,
        ...{
          label: PROTOCOL_INSTANCE_TYPE[protocolInstance.type],
          gateway_ip_interface: protocolInstance.gateway_ip_interface.id,
          gateway_ip_interface_label: protocolInstance.gateway_ip_interface.label,
          gateway_ip_interface_info: protocolInstance.gateway_ip_interface
        }
      })
    }
  }, [protocolInstance])

  const getGatewayInterfaces = async () => {
    setLoadingGateway(true)
    await paginatedRequest(`connectivity/sites/${siteStore.id}/gateway_ip_interfaces/?gateway=${gateway.id}`, auth.userData.access_token)()
      .then(results => {
        setGatewayInterfaces(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingGateway(false))
  }

  useEffect(() => {
    getGatewayInterfaces()
  }, [gateway])

  const updateObject = async function (e) {
    e.preventDefault()
    setLoading(true)
    await update(siteStore.id, protocolInstance.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Protocol instance", t("The protocol instance has been updated."))]))
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      gateway &&
      gateway.id &&
      connector &&
      connector.id &&
      protocolInstance &&
      protocolInstance.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Gateways"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/`
          },
          {
            label: gateway.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/`
          },
          {
            label: t("Connector instances"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/`
          },
          {
            label: connector.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/`
          },
          {
            label: t("Protocol instances"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/`
          },
          {
            label: `${protocolInstance.type} – ${protocolInstance.gateway_ip_interface.label}`,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/${protocolInstance.id}/`
          }
        ])
      )
  }, [siteStore.id, gateway, connector, protocolInstance])

  const additionalComponent = () => {
    return (
      <dl className="overview-resume">
        {object.type === "bacnetip" && (
          <div className="overview-resume-item">
            <dt>{t("Port")}</dt>
            <dd>{object.gateway_udp_port}</dd>
          </div>
        )}
        {object.type === "smxmlapi" && (
          <>
            <div className="overview-resume-item">
              <dt>{t("Batch size")}</dt>
              <dd>{object.batch_size}</dd>
            </div>
            <div className="overview-resume-item">
              <dt>{t("Sleep time")}</dt>
              <dd>{object.sleep_time}</dd>
            </div>
          </>
        )}
        {object.type === "modbusip" && (
          <div className="overview-resume-item">
            <dt>{t("Timeouts")}</dt>
            <dd>
              <button
                className="list-action-button"
                onClick={() => setModbusTimeoutManagement(true)}
              >
                <i className="fa-solid fa-gears" />
                {t("Manage timeout")}
              </button>
            </dd>
          </div>
        )}
      </dl>
    )
  }

  return (
    <>
      {object ? (
        modbusTimeoutManagement ? (
          <ModbusProtocolTimeouts
            siteId={siteStore.id}
            protocolInstance={object}
            setModbusTimeoutManagement={setModbusTimeoutManagement}
          />
        ) : (
          <>
            <OverView
              object={object}
              model={ProtocolInstance(gatewayInterfaces, loadingGateway, true)}
              overviewModel={ProtocolInstanceOverview()}
              additionalComponent={additionalComponent}
              updateObject={updateObject}
              data={data}
              setData={setData}
              errors={errors}
              edit={edit}
              loading={loading}
              setEdit={setEdit}
              deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/${protocolInstance.id}/delete/`}
            />
          </>
        )
      ) : (
        <Loader />
      )}
    </>
  )
}
