import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const organizationStore = useSelector(organization)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["assets"])

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    await remove(organizationStore.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Organization", t("The organization has been removed."))])
        navigate("/")
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        displayNotification([errorNotification("Organization", message)])
      })
  }

  useEffect(() => {
    organizationStore &&
      organizationStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          { label: t("Delete"), url: `/organizations/${organizationStore.id}/delete/` }
        ])
      )
  }, [organizationStore])

  return (
    <>
      {organizationStore && (
        <DeleteConfirmation
          objectType={t("Organization")}
          objectNames={[organizationStore.name]}
          remove={removeObject}
          removeNotAllowedMessage={organizationStore.sites_count > 0 ? t("You can't delete this organization, there is sites linked to it") : null}
          goBack={() => navigate(-1)}
        />
      )}
    </>
  )
}
