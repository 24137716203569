const defaultNS = "common"
const supportedLngs = ["en", "fr"]
const ns = ["assets", "common", "configuration", "connectivity", "energy", "operation", "optimization", "organization"]

const i18nextOptions = {
  defaultNS,
  supportedLngs,
  ns,
  lng: "en",
  fallbackLng: "en",
  fallbackNS: defaultNS,
  interpolation: { escapeValue: false }
}

export { defaultNS, supportedLngs, ns, i18nextOptions }
