import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ButtonWithLoader, FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add, getNodeAllValues, getNodes, getSpaces } from "../api"
import { Equipment } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [data, setData] = useState({ site: siteStore.id })
  const [errors, setErrors] = useState({})
  const [gateways, setGateways] = useState([])
  const [spaces, setSpaces] = useState([])
  const [equipmentTypes, setEquipmentTypes] = useState([])
  const [equipmentSubTypes, setEquipmentSubTypes] = useState([])
  const [processing, setProcessing] = useState(false)
  const [loadingGateways, setLoadingGateways] = useState(false)
  const [loadingSpaces, setLoadingSpaces] = useState(false)
  const [loadingEquipments, setLoadingEquipments] = useState(false)
  const [loadingEquipmentSubTypes, setLoadingEquipmentSubTypes] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const create = async function (e) {
    e.preventDefault()

    setProcessing(true)
    siteStore.id &&
      (await add(siteStore.id, data, auth.userData.access_token)
        .then(response => {
          dispatch(addNotifications([successNotification("Create", t("The equipment has been created."))]))
          navigate(`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/${response.data.id}`)
        })
        .catch(error => {
          const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message

          if (error.response.data) {
            const errorsEquipment = error.response.data
            const errorsEquipCommInfo = {
              ...(errorsEquipment.gateway ? { gateway: errorsEquipment.gateway } : {}),
              ...(errorsEquipment.node ? { node: errorsEquipment.node } : {}),
              ...errorsEquipment.equip_comm_info
            }
            setErrors({
              ...errorsEquipment,
              ...{ equip_comm_info: errorsEquipCommInfo }
            })
          }

          dispatch(addNotifications([errorNotification("Error", message)]))
        })
        .finally(() => setProcessing(false)))
  }

  const retrieveEquipmentTypes = async () => {
    setLoadingEquipments(true)
    // TODO: remove when the api will no longer paginate
    await paginatedRequest("asset/equipment_types/", auth.userData.access_token)()
      .then(results => {
        setEquipmentTypes(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingEquipments(false))

    // TODO: uncomment when the API will no longer paginate
    /* await getEquipmentTypes(auth.userData.access_token).then(response => {
      if (response.data.results) {
        setEquipmentTypes(response.data.results)
      }
    }).catch(error => {
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const retrieveEquipmentSubTypes = async () => {
    setLoadingEquipmentSubTypes(true)
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(`asset/equipment_subtypes/?equipment_type=${data.equipment_type}`, auth.userData.access_token)()
      .then(results => {
        setEquipmentSubTypes(results)
        setData({
          ...data,
          ...{ equipment_subtype: null }
        })
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingEquipmentSubTypes(false))

    // TODO: uncomment when the API will no longer paginate
    /* await getEquipmentSubTypes(data.equipment_type, auth.userData.access_token).then(response => {
      if (response.data.results) {
        setEquipmentSubTypes(response.data.results)
        setData({
          ...data,
          ...{ equipment_subtype: null},
        })
      }
    }).catch(error => {
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const retrieveNodes = async (queryParams = {}) => {
    return await getNodes(siteStore.id, auth.userData.access_token, queryParams)
  }

  const getNodeValues = async () => {
    return await getNodeAllValues(siteStore.id, auth.userData.access_token)
  }

  const retrieveGateways = async () => {
    setLoadingGateways(true)
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(`connectivity/sites/${siteStore.id}/gateways/`, auth.userData.access_token)()
      .then(results => {
        setGateways(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingGateways(false))

    // TODO: uncomment when the API will no longer paginate
    /* await getGateways(siteStore.id, auth.userData.access_token).then(response => {
      if (response.data.results) {
        setGateways(response.data.results)
      }
    }).catch(error => {
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const retrieveSpaces = async () => {
    setLoadingSpaces(true)
    await getSpaces(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        data.results && setSpaces(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingSpaces(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && organizationStore.id) {
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Building"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/`
          },
          {
            label: t("Equipments"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/`
          },
          {
            label: t("Create"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/create/`
          }
        ])
      )

      retrieveGateways()
      retrieveSpaces()
      retrieveEquipmentTypes()
    }
  }, [siteStore.id, organizationStore.id])

  useEffect(() => {
    data.equipment_type ? retrieveEquipmentSubTypes() : setEquipmentSubTypes([])
  }, [data.equipment_type])

  return (
    <>
      {data && (
        <>
          <div className="base-content-title">
            <h2>{t("Create equipment")}</h2>
          </div>
          <form
            className="form-view"
            onSubmit={create}
          >
            <FormContent
              model={Equipment(retrieveNodes, getNodeValues, "", gateways, spaces, equipmentTypes, equipmentSubTypes, loadingGateways, loadingSpaces, loadingEquipments, loadingEquipmentSubTypes)}
              data={data}
              setData={setData}
              errors={errors}
            />
            <div className="action-buttons-wrapper">
              <ButtonWithLoader
                rounded
                severity="success"
                label={t("Save")}
                type="submit"
                disabled={processing || loadingGateways || loadingSpaces || loadingEquipments || loadingEquipmentSubTypes}
                icon="fa-solid fa-floppy-disk"
              />
              <Button
                rounded
                severity="info"
                label={t("Cancel")}
                icon="fa-solid fa-xmark"
                disabled={processing}
                onClick={goBack}
              />
            </div>
          </form>
        </>
      )}
    </>
  )
}
