import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const commonsFields = spaces => {
  return {
    name: {
      label: i18n.t("Name", { ns: "assets" }),
      field: InputText,
      options: {
        required: true,
        maxLength: 255
      }
    },
    model: { label: i18n.t("Model", { ns: "assets" }), field: InputText, options: { maxLength: 255 } },
    manufacturer: { label: i18n.t("Manufacturer", { ns: "assets" }), field: InputText, options: { maxLength: 255 } },
    space: {
      label: i18n.t("Space", { ns: "assets" }),
      field: Dropdown,
      options: {
        options: spaces,
        optionLabel: "name",
        optionValue: "id",
        filter: true
      }
    },
    description: { label: i18n.t("Description", { ns: "assets" }), field: InputTextarea, options: {} }
  }
}

export const commonsOverview = () => {
  return {
    name: { label: i18n.t("Name", { ns: "assets" }) },
    model: { label: i18n.t("Model", { ns: "assets" }) },
    manufacturer: { label: i18n.t("Manufacturer", { ns: "assets" }) },
    space_label: { label: i18n.t("Space", { ns: "assets" }) },
    description: { label: i18n.t("Description", { ns: "assets" }) }
  }
}
