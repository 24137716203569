import { errorNotification, successNotification } from "/utils/notification.js"
import { updateBreadcrumb } from "/utils/store/breadcrumb.js"
import { addNotifications } from "/utils/store/notification.js"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar.js"
import { site } from "/utils/store/site"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { removeUnavailabilityPeriods } from "../api"

const DeleteBulk = () => {
  const auth = useAuth()
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { t } = useTranslation(["assets"])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [processing, setProcessing] = useState(false)
  const ids = searchParams.getAll("id")

  const bulkDelete = async () => {
    setProcessing(true)
    await removeUnavailabilityPeriods(siteStore.id, { unavailability_period_ids: ids }, auth.userData.access_token)
      .then(({ status, statusText }) => {
        if (status === 204) {
          dispatch(addNotifications([successNotification("Unavailability periods", t("Unavailability periods has been removed."))]))
          navigate(`/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-periods/`)
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Unavailability periods", message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          {
            label: t("Unavailability periods"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-periods/`
          },
          {
            label: t("Delete"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-periods/bulk-delete/`
          }
        ])
      )
  }, [siteStore.id])

  return (
    <DeleteConfirmation
      objectType={t("Stores")}
      objectNames={[`${ids.length} ${t("Unavailability periods")}`]}
      remove={bulkDelete}
      processing={processing}
      goBack={() => navigate(-1)}
    />
  )
}

export default DeleteBulk
