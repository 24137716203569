import MarkerClusterGroup from "/apps/assets/site/components/MarkerClusterGroup"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { organization } from "/utils/store/organization"
import { Icon, Point } from "leaflet"
import { Button } from "primereact/button"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import icon from "static/images/marker.png"

const defaultCenter = [47, 3]
const customMarkerIcon = new Icon({
  iconUrl: `/${icon}`,
  iconSize: new Point(33, 33, true)
})

const createClusterCustomIcon = cluster => {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: new Point(33, 33, true)
  })
}

const calculateCenter = coordinates => {
  const total = coordinates.reduce(
    (accumulator, current) => {
      return {
        latitude: accumulator.latitude + parseInt(current.latitude),
        longitude: accumulator.longitude + parseInt(current.longitude)
      }
    },
    { latitude: 0, longitude: 0 }
  )

  return [total.latitude / coordinates.length, total.longitude / coordinates.length]
}

const MapCenter = ({ sites }) => {
  const map = useMap()

  useEffect(() => {
    if (sites.length) {
      const coordinates = sites.map(site => ({ latitude: site.latitude, longitude: site.longitude }))
      const center = calculateCenter(coordinates)

      map.setView(center, map.getZoom(), { animate: true })

      !!sites.length &&
        sites.length > 1 &&
        map.fitBounds(
          sites.map(site => [site.latitude, site.longitude]),
          { animate: true }
        )
    }
  }, [sites])

  return null
}

export default function SitesMap({ sites }) {
  const organizationStore = useSelector(organization)
  const { t } = useTranslation(["organization"])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const openSite = id => navigate(`/organizations/${organizationStore.id}/sites/${id}`)

  useEffect(() => {
    organizationStore.id &&
      dispatch(
        updateBreadcrumb([
          {
            label: organizationStore.name,
            url: `/organizations/${organizationStore.id}/sites/`
          }
        ])
      )
  }, [organizationStore.id])

  return (
    <div className="map-wrapper">
      {sites && !!sites.length && (
        <MapContainer
          className="map-container"
          center={defaultCenter}
          zoom={6}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          />
          <MarkerClusterGroup
            chunkedLoading
            iconCreateFunction={createClusterCustomIcon}
          >
            {sites.map((site, index) => (
              <Marker
                key={index}
                icon={customMarkerIcon}
                position={[site.latitude, site.longitude]}
              >
                <Popup>
                  <div className="popup-site">
                    <h2>
                      {t("Site")} {site.name}
                    </h2>
                    <p>{`${site.address}, ${site.zip_code} ${site.city}, ${site.country}`}</p>
                    <Button
                      className="popup-button"
                      rounded
                      severity="info"
                      label={t("Open")}
                      icon="fas fa-door-open"
                      onClick={() => openSite(site.id)}
                    />
                  </div>
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
          <MapCenter sites={sites} />
        </MapContainer>
      )}
    </div>
  )
}
