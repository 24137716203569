import { ArrayInput, Dropdown, SelectArray } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const Point = (retrieveEquipments, getEquipmentsValues, equipment_label, retrieveAutomationPoints, getAutomationPointValues, automation_point_label, parameters, stores, loadingParameters, loadingStores) => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: {
      label: i18n.t("Name", { ns: "assets" }),
      field: InputText,
      options: {
        required: true,
        keyfilter: /[\w\d\-_]/,
        maxLength: 19
      }
    },
    description: { label: i18n.t("Description", { ns: "assets" }), field: InputTextarea, options: {} },
    equipment: {
      label: i18n.t("Equipment", { ns: "assets" }),
      field: SelectArray,
      options: {
        required: true,
        getItems: retrieveEquipments,
        getAllValues: getEquipmentsValues,
        columns: [
          { field: "name", header: i18n.t("Name", { ns: "assets" }), sortable: true },
          { field: "node_name", header: i18n.t("Node", { ns: "assets" }), sortable: true },
          { field: "gateway_name", header: i18n.t("Gateway", { ns: "assets" }), sortable: true }
        ],
        columnsFilter: [
          { field: "name", header: i18n.t("Name", { ns: "assets" }), sortable: true },
          { field: "node_name", header: i18n.t("Node", { ns: "assets" }), sortable: true },
          { field: "gateway_name", header: i18n.t("Gateway", { ns: "assets" }), sortable: true }
        ],
        searchLabel: (id, items) => {
          let find = items.find(equipment => equipment.id === id)
          return find ? find.name : ""
        },
        LABEL_VALUE: equipment_label
      }
    },
    automation_point: {
      label: i18n.t("Automation point", { ns: "assets" }),
      field: SelectArray,
      options: {
        getItems: retrieveAutomationPoints,
        getAllValues: getAutomationPointValues,
        columns: [
          { field: "name", header: i18n.t("Name", { ns: "assets" }), sortable: true },
          { field: "node_name", header: i18n.t("Node", { ns: "assets" }), sortable: true },
          { field: "description", header: i18n.t("Description", { ns: "assets" }), sortable: true }
        ],
        columnsFilter: [
          { field: "name", header: i18n.t("Name", { ns: "assets" }), sortable: true },
          { field: "node_name", header: i18n.t("Node", { ns: "assets" }), sortable: true },
          { field: "description", header: i18n.t("Description", { ns: "assets" }), sortable: true }
        ],
        searchLabel: (id, items) => {
          let find = items.find(automationPoint => automationPoint.id === id)
          return find ? find.name : ""
        },
        LABEL_VALUE: automation_point_label
      },
      dependsOn: "equipment"
    },
    read: {
      label: i18n.t("Read", { ns: "assets" }),
      field: InputSwitch
    },
    write: {
      label: i18n.t("Write", { ns: "assets" }),
      field: InputSwitch
    },
    parameter: {
      label: i18n.t("Unit", { ns: "assets" }),
      field: Dropdown,
      options: {
        options: parameters,
        optionLabel: "context_name",
        optionValue: "id",
        loading: loadingParameters,
        placeholder: loadingParameters ? "Loading..." : "Select parameter"
      }
    },
    time_series_storage_info: {
      store_type: {
        label: i18n.t("Store type", { ns: "assets" }),
        field: Dropdown,
        options: {
          options: [
            { label: i18n.t("Gauge", { ns: "assets" }), value: "gauge" },
            { label: i18n.t("Status", { ns: "assets" }), value: "status" }
          ],
          optionLabel: "label",
          optionValue: "value",
          placeholder: "Select store type"
        }
      },
      store: {
        label: i18n.t("Store", { ns: "assets" }),
        field: Dropdown,
        options: {
          options: stores,
          optionLabel: "name",
          optionValue: "id",
          loading: loadingStores,
          placeholder: loadingStores ? "Loading..." : "Select store"
        }
        // dependsOn: "store_type"
      },
      disable_storage: {
        label: i18n.t("Disable storage", { ns: "assets" }),
        field: InputSwitch,
        dependsOn: "store_type"
      },
      linear_multiplier: {
        label: i18n.t("Linear multiplier", { ns: "assets" }),
        field: InputNumber,
        options: { showButtons: true },
        dependsOn: "store_type",
        dependsOnValue: "gauge"
      },
      linear_constant: {
        label: i18n.t("Linear constant", { ns: "assets" }),
        field: InputNumber,
        options: { showButtons: true },
        dependsOn: "store_type",
        dependsOnValue: "gauge"
      },
      acceptable_values: {
        field: ArrayInput,
        dependsOn: "store_type",
        dependsOnValue: "status",
        options: {
          columns: [
            { field: "original_point_value", header: i18n.t("Original point value", { ns: "assets" }) },
            { field: "gateway_point_value", header: i18n.t("Gateway point value", { ns: "assets" }) },
            { field: "gateway_point_text", header: i18n.t("Gateway point text", { ns: "assets" }) }
          ],
          modelForm: () => {
            return {
              original_point_value: {
                label: i18n.t("Original point value", { ns: "assets" }),
                field: InputNumber,
                options: { showButtons: true, required: true }
              },
              gateway_point_value: {
                label: i18n.t("Gateway point value", { ns: "assets" }),
                field: InputNumber,
                options: { showButtons: true, required: true }
              },
              gateway_point_text: {
                label: i18n.t("Gateway point text", { ns: "assets" }),
                field: InputText
              }
            }
          },
          label: i18n.t("Acceptable values", { ns: "assets" }),
          createLabel: i18n.t("Add values", { ns: "assets" })
        }
      }
    }
  }
}

export const PointOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: i18n.t("Name", { ns: "assets" }) },
    description: { label: i18n.t("Description", { ns: "assets" }) },
    equipment_name: { label: i18n.t("Equipment", { ns: "assets" }) },
    automation_point_name: { label: i18n.t("Automation point", { ns: "assets" }) },
    read_write: { label: i18n.t("Read / Write", { ns: "assets" }) }
  }
}
