import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { Loader, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { retrieve, update } from "../api"
import { CircuitBreaker } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [object, setObject] = useState(null)
  const { id } = useParams()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [loading, setLoading] = useState(true)

  const retrieveDetail = async () => {
    setLoading(true)

    await retrieve(id, auth.userData.access_token)
      .then(response => {
        setObject(response.data)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoading(false))
  }

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await update(id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The OBJECT_NAME has been updated."))]))
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    //dispatch(updateSideBar({menuName: "site", siteId: siteId})

    /* dispatch(
    updateBreadcrumb([
      {label: "Home", url: "/"},
      {label: "Templates", url: "/templates/"},
    ])
    ) */

    retrieveDetail()
  }, [id])

  return (
    <>
      {object ? (
        <>
          <div className="base-content-title">
            <h1 className="title">
              {t("OBJECT_NAME")} {object.name}
            </h1>
          </div>
          <OverView
            object={object}
            model={CircuitBreaker()}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            loading={loading}
            processing={processing}
            setEdit={setEdit}
            deleteUrl={"DELETE_URL"}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}
