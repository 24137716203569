import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const ConnectorInstance = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: {
      label: i18n.t("Name", { ns: "connectivity" }),
      field: InputText,
      options: {
        required: true,
        maxLength: 255
      }
    },
    stores_response_timeout: {
      label: i18n.t("Response timeout", { ns: "connectivity" }),
      field: InputNumber,
      options: {
        required: true,
        showButtons: true
      }
    },
    description: {
      label: i18n.t("Description", { ns: "connectivity" }),
      field: InputTextarea,
      options: {}
    }
  }
}
