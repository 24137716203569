import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/`, config({ token, queryParams }))
}

export async function retrieve(siteId, equimentId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/${equimentId}/`, config({ token }))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/`, data, config({ token }))
}

export async function update(siteId, equimentId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/${equimentId}/`, data, config({ token }))
}

export async function remove(siteId, equimentId, token) {
  return await axios.delete(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/${equimentId}/`, config({ token }))
}

export async function getNodes(siteId, token, queryParams) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/`, config({ token, queryParams }))
}

export async function getNodeAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/allvalues`, config({ token }))
}

export async function getGateways(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/`, config({ token }))
}

export async function getSpaces(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, config({ token }))
}

export async function getEquipmentTypes(token) {
  return await axios.get(`${window.BACKEND_URL}/asset/equipment_types/`, config({ token }))
}

export async function getEquipmentSubTypes(equipmentTypeId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/equipment_subtypes/`, config({ token, queryParams: { equipment_tyoe: equipmentTypeId } }))
}

export async function getEquipmentsCount(ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/equipments/count/`, ids, config({ token }))
}

export async function removeEquipments(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/deletion/`, ids, config({ token }))
}

export async function getAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/allvalues`, config({ token }))
}

export async function exportEquipments(siteId, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/${siteId}/equipments/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function exportTemplate(token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/0/equipments/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importEquipments(siteId, file, token) {
  let formData = new FormData()
  formData.append("file", file)
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/bulk/import`, formData, config({ token, file: true }), { signal: new AbortController() })
}
