import { GatewayRoutesContext } from "/apps/connectivity/gateway/routes"
import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { getSpaces, update } from "../api"
import { Gateway, GatewayOverview } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [hardwares, setHardwares] = useState([])
  const [spaces, setSpaces] = useState([])
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [loadingHardwares, setLoadingHardwares] = useState(false)
  const [loadingSpaces, setLoadingSpaces] = useState(false)
  const { gateway, retrieveDetail } = useContext(GatewayRoutesContext)

  const updateObject = async e => {
    e.preventDefault()
    setProcessing(true)
    await update(siteStore.id, gateway.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The gateway has been updated."))]))
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications(errorNotification("Error", message)))
      })
      .finally(() => setProcessing(false))
  }

  const getHW = async () => {
    setLoadingHardwares(true)
    await paginatedRequest("connectivity/hardwares/", auth.userData.access_token)()
      .then(results => {
        setHardwares(results)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoadingHardwares(false))
  }

  const getSP = async () => {
    setLoadingSpaces(true)
    await getSpaces(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        setSpaces(data.results)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoadingSpaces(false))
  }

  const hardwareInfos = () => {
    return (
      <>
        {gateway && gateway.hardware_info && (
          <div className="list-section">
            <div className="list-section-head">
              <h3>{t("Hardware")}</h3>
            </div>

            <dl className="overview-resume">
              <div className="overview-resume-item">
                <dt>{t("Model")}</dt>
                <dd>{gateway.hardware_info.model}</dd>
              </div>
              <div className="overview-resume-item">
                <dt>{t("Manufacturer")}</dt>
                <dd>{gateway.hardware_info.manufacturer}</dd>
              </div>
              <div className="overview-resume-item">
                <dt>{t("Serial ID")}</dt>
                <dd>{gateway.hardware_info.serial_id}</dd>
              </div>
              <div className="overview-resume-item">
                <dt>{t("Description")}</dt>
                <dd>{gateway.hardware_info.description}</dd>
              </div>
            </dl>
          </div>
        )}
      </>
    )
  }

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (gateway) {
      setLoading(false)
      setData(gateway)
    }
  }, [gateway])

  useEffect(() => {
    siteStore.id &&
      gateway &&
      gateway.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          { label: t("Gateways"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/` },
          {
            label: gateway.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/`
          }
        ])
      )
  }, [siteStore.id, gateway])

  useEffect(() => {
    // Only if edit mode
    if (edit) {
      getHW()
      getSP()
    }
  }, [edit])

  return (
    <>
      {gateway && (
        <>
          <nav>
            <Link to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/?gateway_name=${gateway.name}`}>
              <Button
                link
                rounded
                severity="secondary"
                label="Equipments"
                icon="fa-solid fa-square-arrow-up-right"
              />
            </Link>
            <Link to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/?gateway_name=${gateway.name}`}>
              <Button
                link
                rounded
                severity="secondary"
                label="Points"
                icon="fa-solid fa-square-arrow-up-right"
              />
            </Link>
          </nav>

          <OverView
            object={gateway}
            model={Gateway(hardwares, spaces, loadingHardwares, loadingSpaces)}
            overviewModel={GatewayOverview()}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            setEdit={setEdit}
            loading={loading}
            processing={processing}
            deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/delete/`}
            additionalComponent={hardwareInfos}
          />
        </>
      )}
    </>
  )
}
