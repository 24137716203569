import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { retrieveSpaces, update } from "../api"
import { UnavailabilityCategory } from "../models"
import { UnavailabilityCategoryRouteContext } from "../routes"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const { unavailabilityCategory, retrieveDetail } = useContext(UnavailabilityCategoryRouteContext)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [spaces, setSpaces] = useState([])

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await update(siteStore.id, unavailabilityCategory.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The unavailability category has been updated."))]))
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setProcessing(false))
  }

  const getSpaces = async () => {
    await retrieveSpaces(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        setSpaces(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
    siteStore.id && getSpaces()
  }, [siteStore.id])

  useEffect(() => {
    if (unavailabilityCategory) {
      setLoading(false)
      setData(unavailabilityCategory)
    }
  }, [unavailabilityCategory])

  useEffect(() => {
    if (siteStore.id) {
      unavailabilityCategory &&
        unavailabilityCategory.id &&
        dispatch(
          updateBreadcrumb([
            { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
            {
              label: siteStore.pretty_name ?? siteStore.name,
              url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
            },
            {
              label: t("Unavailability categories"),
              url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-categories/`
            },
            ,
            {
              label: unavailabilityCategory.name,
              url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-categories/${unavailabilityCategory.id}/`
            }
          ])
        )
    }
  }, [siteStore.id, unavailabilityCategory])

  return (
    <>
      {unavailabilityCategory && (
        <>
          <div className="base-content-title">
            <h2>{unavailabilityCategory.name}</h2>
          </div>

          <OverView
            object={unavailabilityCategory}
            model={UnavailabilityCategory(spaces)}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            loading={loading}
            processing={processing}
            setEdit={setEdit}
            deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-categories/${unavailabilityCategory.id}/delete/`}
          />
        </>
      )}
    </>
  )
}
