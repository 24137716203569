import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { ButtonWithLoader, FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add } from "../api"
import { GatewayBox } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["configuration"])
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [processing, setProcessing] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { label: t("Gateways"), url: "/configuration/gateways/boxes/" },
        { label: t("Boxes"), url: "/configuration/gateways/boxes/" },
        { label: t("Create"), url: "/configuration/gateways/boxes/create/" }
      ])
    )
  }, [])

  const create = async function (e) {
    e.preventDefault()

    setProcessing(true)
    await add(data, auth.userData.access_token)
      .then(res => {
        dispatch(addNotifications([successNotification("Gateway Box", t("The gateway box has been created."))]))
        navigate(`/configuration/gateways/boxes/${res.data.id}`)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Gateway Box", message)]))
      })
      .finally(() => setProcessing(false))
  }

  return (
    <>
      {data && (
        <>
          <div className="base-content-title">
            <h2>{t("Create box")}</h2>
          </div>
          <form
            className="form-view"
            onSubmit={create}
          >
            <FormContent
              model={GatewayBox()}
              data={data}
              setData={setData}
              errors={errors}
            />
            <div className="action-buttons-wrapper">
              <ButtonWithLoader
                rounded
                severity="success"
                label={t("Save")}
                type="submit"
                disabled={processing}
                icon="fa-solid fa-floppy-disk"
              />
              <Button
                rounded
                severity="info"
                label={t("Cancel")}
                icon="fa-solid fa-xmark"
                disabled={processing}
                onClick={goBack}
              />
            </div>
          </form>
        </>
      )}
    </>
  )
}
