import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(siteId, token, queryParams) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/stores/`, config({ token, queryParams }))
}

export async function retrieve(siteId, storeId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/stores/${storeId}/`, config({ token }))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/connectivity/sites/${siteId}/stores/`, data, config({ token }))
}

export async function update(siteId, storeId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/connectivity/sites/${siteId}/stores/${storeId}/`, data, config({ token }))
}

export async function remove(siteId, storeId, token) {
  return await axios.delete(`${window.BACKEND_URL}/connectivity/sites/${siteId}/stores/${storeId}/`, config({ token }))
}

export async function removeStores(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/connectivity/sites/${siteId}/stores/deletion/`, ids, config({ token }))
}

export async function getCount(storeId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/stores/${storeId}/count`, config({ token }))
}

export async function retrieveConnectorInstances(siteId, gatewayId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/connector_instances/`, config({ token, queryParams: { gateway: gatewayId } }))
}

export async function getAllValues(gatewayId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/gateway/${gatewayId}/stores/allvalues`, config({ token }))
}
