import { getNetworksCount, removeNetworks } from "/apps/assets/communication/network/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation, Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

const DeleteBulk = () => {
  const auth = useAuth()
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { t } = useTranslation(["assets"])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [objectCount, setObjectCount] = useState()
  const [loadingRelatedObject, setLoadingRelatedObject] = useState(false)
  const [processing, setProcessing] = useState(false)
  const ids = searchParams.getAll("id")

  const count = async () => {
    setLoadingRelatedObject(true)
    await getNetworksCount(ids, auth.userData.access_token)
      .then(({ data, status, statusText }) => {
        if (status === 200) {
          setObjectCount(data)
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Networks", error.message)]))
      })
      .finally(() => setLoadingRelatedObject(false))
  }

  const bulkDelete = async () => {
    setProcessing(true)
    await removeNetworks(siteStore.id, ids, auth.userData.access_token)
      .then(({ status, statusText }) => {
        if (status === 200) {
          dispatch(addNotifications([successNotification("Networks", t("Networks has been removed."))]))
          navigate(`/sites/${siteStore.id}/assets/communication/networks/`)
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Networks", message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && organizationStore.id) {
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Communication"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/networks/`
          },
          {
            label: t("Networks"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/networks/`
          },
          {
            label: t("Delete"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/networks/delete/`
          }
        ])
      )

      count()
    }
  }, [siteStore.id, organizationStore.id])

  return loadingRelatedObject ? (
    <Loader />
  ) : (
    objectCount && objectCount.label && !!objectCount.label.length && (
      <DeleteConfirmation
        objectType={t("Network")}
        objectNames={objectCount.label}
        relatedObjects={objectCount}
        remove={bulkDelete}
        processing={processing}
        goBack={() => navigate(-1)}
      />
    )
  )
}

export default DeleteBulk
