import { SelectArray } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { commonsFields, commonsOverview } from "./commons"

export const CarelNetworkNode = (spaces, retrieveCarelNodes, getAllValues, label_value = "") => {
  return {
    ...commonsFields(spaces),
    ...{
      carel_node: {
        label: i18n.t("Carel node", { ns: "assets" }),
        field: SelectArray,
        options: {
          getItems: retrieveCarelNodes,
          getAllValues: getAllValues,
          columns: [
            { field: "name", header: i18n.t("Name", { ns: "assets" }) },
            { field: "model", header: i18n.t("Model", { ns: "assets" }) },
            { field: "manufacturer", header: i18n.t("Manufacturer", { ns: "assets" }) },
            { field: "target", header: i18n.t("Target", { ns: "assets" }) },
            { field: "scheme", header: i18n.t("Scheme", { ns: "assets" }) },
            { field: "tls_version", header: i18n.t("TLS version", { ns: "assets" }) },
            { field: "low_cipher", header: i18n.t("Low cipher", { ns: "assets" }) },
            { field: "user", header: i18n.t("User", { ns: "assets" }) }
          ],
          columnsFilter: [
            { field: "name", header: i18n.t("Name", { ns: "assets" }) },
            { field: "model", header: i18n.t("Model", { ns: "assets" }) },
            { field: "manufacturer", header: i18n.t("Manufacturer", { ns: "assets" }) },
            { field: "target", header: i18n.t("Target", { ns: "assets" }) },
            { field: "scheme", header: i18n.t("Scheme", { ns: "assets" }) },
            { field: "tls_version", header: i18n.t("TLS version", { ns: "assets" }) },
            { field: "low_cipher", header: i18n.t("Low cipher", { ns: "assets" }) },
            { field: "user", header: i18n.t("User", { ns: "assets" }) }
          ],
          required: true,
          searchLabel: (id, items) => {
            let find = items.find(node => node.id === id)
            return find ? find.name : ""
          },
          LABEL_VALUE: label_value
        }
      },
      id_device: {
        label: i18n.t("Device ID", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      },
      status: {
        label: i18n.t("Status", { ns: "assets" }),
        field: InputNumber,
        options: {
          showButtons: true
        }
      },
      address: {
        label: i18n.t("Address", { ns: "assets" }),
        field: InputNumber,
        options: {
          showButtons: true
        }
      },
      id_line: {
        label: i18n.t("Line ID", { ns: "assets" }),
        field: InputNumber,
        options: {
          showButtons: true
        }
      },
      type_protocol: {
        label: i18n.t("Protocol type", { ns: "assets" }),
        field: InputText,
        options: { maxLength: 255 }
      }
    }
  }
}

export const CarelNetworkOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      carel_node_label: { label: i18n.t("Carel node", { ns: "assets" }) },
      id_device: { label: i18n.t("Device ID", { ns: "assets" }) },
      status: { label: i18n.t("Status", { ns: "assets" }) },
      address: { label: i18n.t("Address", { ns: "assets" }) },
      id_line: { label: i18n.t("Line ID", { ns: "assets" }) },
      type_protocol: { label: i18n.t("Protocol type", { ns: "assets" }) }
    }
  }
}
