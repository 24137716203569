import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import { retrieve } from "./api"
import Create from "./components/Create"
import Delete from "./components/Delete"
import DeleteBulk from "./components/DeleteBulk"
import Detail from "./components/Detail"

import List from "./components/List"

const NodeRoutesContext = createContext(null)

const NodeContent = () => {
  const auth = useAuth()
  const siteStore = useSelector(site)
  const { siteId, nodeId } = useParams()
  const [node, setNode] = useState()
  const dispatch = useDispatch()

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, nodeId, auth.userData.access_token)
      .then(response => {
        setNode(response.data)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
  }

  useEffect(() => {
    siteId && nodeId && retrieveDetail()
  }, [siteStore.id, nodeId])

  return <NodeRoutesContext.Provider value={{ node, retrieveDetail }}>{node ? <Outlet /> : <Loader />}</NodeRoutesContext.Provider>
}

export { NodeRoutesContext }
export default function AutomationNodeRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path="multi-delete"
        element={<DeleteBulk />}
      />
      <Route
        path=":nodeId"
        element={<NodeContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
