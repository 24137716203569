import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { retrieveList, getAllValues } from "../api"
import { buildQueryParams } from "/utils/queryparams"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [count, setCount] = useState(0)
  const [selected, setSelected] = useState([])
  const [sortOrder, setSortOrder] = useState(null)
  const [sortField, setSortField] = useState(null)

  const retrieve = async (queryParams = `?limit=${limit}&offset=${offset}`) => {
    setLoading(true)

    await retrieveList(siteStore.id, queryParams, auth.userData.access_token)
      .then(({ data }) => {
        data.results &&
          setItems(
            data.results.map(result => {
              return {
                ...result,
                space_name: result.space
              }
            })
          )
        setCount(data.count)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Unavailability categories", message)]))
      })
      .finally(() => setLoading(false))
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(siteStore.id, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "scope", header: t("Scope"), sortable: true },
    { field: "space_name", header: t("Space"), sortable: true }
  ]

  const columnsFilter = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "scope", header: t("Scope"), sortable: true },
    { field: "space_name", header: t("Space"), sortable: true }
  ]

  const getDetailUrl = objectId => `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-categories/${objectId}/`

  const getDeleteUrl = objectId => `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-categories/${objectId}/delete/`

  const onRowClick = objectId => navigate(getDetailUrl(objectId))

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          {
            label: t("Unavailability categories"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-categories/`
          }
        ])
      )
      getFilterValues()
    }
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id && (offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieve(buildQueryParams(limit, offset, filters, sortField, sortOrder)))
  }, [filters, sortField, sortOrder])

  useEffect(() => {
    siteStore.id && retrieve(buildQueryParams(limit, offset, filters, sortField, sortOrder))
  }, [limit, offset])

  return (
    <section className="list-section">
      <ListActions
        filterBackend={true}
        filters={menuFilters}
        loading={loadingFilters}
      >
        <Link to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-categories/create/`}>
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Unavailability categories")}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        count={count}
        bulkDeleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-categories/multi-delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
        handleSelection={setSelected}
        onSort={onSort}
        sortOrder={sortOrder}
        sortField={sortField}
      />
    </section>
  )
}
