import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { commonsFields, commonsOverview } from "./commons"

export const SMNode = spaces => {
  return {
    ...commonsFields(spaces),
    ...{
      proto: {
        label: i18n.t("Protocol", { ns: "assets" }),
        field: Dropdown,
        options: {
          options: [
            { value: "HTTP", label: i18n.t("HTTP", { ns: "assets" }) },
            { value: "HTTPS", label: i18n.t("HTTPS", { ns: "assets" }) }
          ],
          required: true
        }
      },
      sm_type: {
        label: i18n.t("SM Type", { ns: "assets" }),
        field: Dropdown,
        options: {
          options: [
            { value: "AK-SM8XX", label: "AK-SM8XX" },
            { value: "AK-SM8XXA", label: "AK-SM8XXA" },
            { value: "AK-SC355", label: "AK-SC355" }
          ],
          required: true
        }
      },
      store_name: {
        label: "Store name",
        field: InputText,
        options: { maxLength: 255 }
      },
      software: {
        label: i18n.t("Software", { ns: "assets" }),
        field: InputText,
        options: { maxLength: 255 }
      },
      user: {
        label: i18n.t("User", { ns: "assets" }),
        field: InputText,
        options: {
          required: true,
          maxLength: 255
        }
      },
      password: {
        label: i18n.t("Password", { ns: "assets" }),
        field: InputText,
        options: {
          required: true,
          type: "password",
          maxLength: 255
        }
      },
      confirm_password: {
        label: i18n.t("Confirm password", { ns: "assets" }),
        field: InputText,
        options: {
          required: true,
          type: "password",
          maxLength: 255
        }
      }
    }
  }
}

export const SMOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      proto: { label: i18n.t("Protocol", { ns: "assets" }) },
      sm_type: { label: i18n.t("SM Type", { ns: "assets" }) },
      store_name: { label: "Store name" },
      software: { label: i18n.t("Software", { ns: "assets" }) },
      user: { label: i18n.t("User", { ns: "assets" }) }
    }
  }
}
