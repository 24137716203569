import { site } from "/utils/store/site"
import { TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Route, Routes, useLocation } from "react-router-dom"
// import EdgeControllerRoutes from "./edge-controller/routes"
// import PointRoutes from "./point/routes"
import StateRoutes from "./state/routes"

export default function ConfigurationRoutes() {
  /* Tabs to ListLayout component*/
  const siteStore = useSelector(site)
  const location = useLocation()
  const { t } = useTranslation(["assets"])
  const [tabs, setTabs] = useState([
    //{ name: t("Points writable"), active: location.pathname.includes("points") ? true : false, url: `/sites/${siteStore.id}/optimization/configuration/points/` },
    {
      name: t("States"),
      active: location.pathname.includes("states"),
      url: `/sites/${siteStore.id}/optimization/configuration/states/`
    }
    // { name: t("Edge controllers"), active: location.pathname.includes("edge-controllers") ? true : false, url: `/sites/${siteStore.id}/optimization/configuration/edge-controllers/` }
  ])

  useEffect(() => {
    setTabs([
      {
        name: t("Points writable"),
        active: location.pathname.includes("points"),
        url: `/sites/${siteStore.id}/optimization/configuration/points/`
      },
      {
        name: t("States"),
        active: location.pathname.includes("states"),
        url: `/sites/${siteStore.id}/optimization/configuration/states/`
      },
      {
        name: t("Edge controllers"),
        active: location.pathname.includes("edge-controllers"),
        url: `/sites/${siteStore.id}/optimization/configuration/edge-controllers/`
      }
    ])
  }, [siteStore.id])

  return (
    <Routes>
      <Route
        element={
          <TabsLayout
            title={t("Configuration")}
            tabs={tabs}
            setTabs={setTabs}
          />
        }
      >
        {/*
        <Route
          index
          path="/points/*"
          element={<PointRoutes />}
        />
        <Route
          path="/edge-controllers/*"
          element={<EdgeControllerRoutes />}
        />
        */}
        <Route
          path="/states/*"
          element={<StateRoutes />}
        />
      </Route>
    </Routes>
  )
}
