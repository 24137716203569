import CircuitBreakerRoutes from "/apps/assets/electrical/circuit-breaker/routes.js"
import { organization } from "/utils/store/organization"
import { site } from "/utils/store/site"
import { TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Route, Routes, useLocation } from "react-router-dom"

const ElectricalRoute = () => {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const location = useLocation()
  const { t } = useTranslation(["assets"])
  const [tabs, setTabs] = useState([
    {
      name: t("Circuit breakers"),
      active: location.pathname.includes("circuit-breakers"),
      url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/`
    }
  ])

  return (
    <Routes>
      <Route
        element={
          <TabsLayout
            title={t("Electrical")}
            tabs={tabs}
            setTabs={setTabs}
          />
        }
      >
        <Route
          index
          path="circuit-breakers/*"
          element={<CircuitBreakerRoutes />}
        />
      </Route>
    </Routes>
  )
}

export default ElectricalRoute
