import { ContractRoutesContext } from "/apps/energy/contract/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["assets"])
  const { contract } = useContext(ContractRoutesContext)
  const [processing, setProcessing] = useState(false)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    setProcessing(true)
    await remove(siteStore.id, contract.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The contract has been removed."))])
        navigate(`/organizations/${organizationStore.id}/sites/${siteStore.id}/energy/contracts/`)
      })
      .catch(error => {
        displayNotification([errorNotification("Error", error.message)])
      })
      .finally(() => setProcessing(true))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      contract &&
      contract.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          {
            label: t("Contracts"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/energy/contracts/`
          },
          {
            label: contract.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/energy/contracts/${contract.id}/`
          },
          {
            label: t("Delete"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/energy/contracts/${contract.id}/delete/`
          }
        ])
      )
  }, [siteStore.id, organizationStore.id, contract])

  return (
    <>
      {contract && (
        <DeleteConfirmation
          objectType={t("Contract")}
          objectNames={[contract.name]}
          remove={removeObject}
          processing={processing}
          goBack={() => navigate(-1)}
        />
      )}
    </>
  )
}
