import i18n from "i18next"
import { InputSwitch } from "primereact/inputswitch"
import { InputTextarea } from "primereact/inputtextarea"

export const FlexOpti = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    active: { label: i18n.t("Active", { ns: "optimization" }), field: InputSwitch, options: {} },
    comment: { label: i18n.t("Comment", { ns: "optimization" }), field: InputTextarea, options: {} }
  }
}
