import { StateRoutesContext } from "/apps/optimization/configuration/state/routes"
import { paginatedRequest } from "/utils/api"
import { datetimeStr } from "/utils/datetime"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout, Loader, ModalListChoices, ReturnButton } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { exportStateValues, getHistory, getStateArchive, importStateValues } from "../api"

const fileDownload = require("js-file-download")

const StateValuesTemplate = ({ state, siteId, visible, setVisible }) => {
  const [points, setPoints] = useState([])
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])

  const validate = values => {
    console.log("/* TODO : Send ids of values to api and receive a csv as response ")
    console.log(values)
    console.log("*****/")
    setVisible(false)
  }

  useEffect(() => {
    const getWP = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`asset/sites/${siteId}/points/?write=True&has_automation_point=True&gateway=${state.gateway}`, auth.userData.access_token)()
        .then(results => {
          setPoints(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getWritablePoints(siteId, state.gateway, auth.userData.access_token).then(response => {
        setPoints(response.data.results)
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }

    getWP()
  }, [])

  return (
    <ModalListChoices
      items={points}
      columns={[
        { field: "name", header: t("Name") },
        { field: "equipment_name", header: t("Equipment") },
        { field: "automation_point_name", header: t("Automation point") },
        { field: "node", header: t("Node") },
        { field: "read_write", header: t("Read / Write") }
      ]}
      visible={visible}
      setVisible={setVisible}
      validate={validate}
    />
  )
}

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [object, setObject] = useState(null)
  // const [data, setData] = useState({})
  const [visible, setVisible] = useState(false)
  const [historyStates, setHistoryStates] = useState([])
  // const [gateways, setGateways] = useState([])
  // const [edit, setEdit] = useState(false)
  // const [loading, setLoading] = useState(true)
  const hiddenFileInput = useRef(null)
  const { state, retrieveDetail } = useContext(StateRoutesContext)

  // const updateObject = async function (e) {
  //   e.preventDefault()
  //   await update(siteStore.id, state.id, data, auth.userData.access_token)
  //     .then(() => {
  //       dispatch(addNotifications([successNotification("Update", t("The state has been updated."))]))
  //       setLoading(true)
  //       retrieveDetail()
  //       setEdit(false)
  //     })
  //     .catch(error => {
  //       if (error.response.data) {
  //         console.log(error.response.data)
  //       }
  //       dispatch(addNotifications([errorNotification("Error", error.message)]))
  //     })
  // }

  const getExport = async function () {
    await exportStateValues(siteStore.id, state.id, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `${object.name}_${object.type}_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const getArchive = async function () {
    await getStateArchive(siteStore.id, state.id, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, response.data.type === "application/tgz" ? response.headers["content-disposition"].split("filename=")[1].split(";")[0] : `ERROR_opti_${object.type}_${object.name}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const postImport = async function (target) {
    const file = target.files[0]

    if (file) {
      await importStateValues(siteStore.id, state.id, file, auth.userData.access_token)
        .then(response => {
          if (response.data.id) {
            dispatch(addNotifications([successNotification("Import", response.data.message)]))
            navigate(`/sites/${siteStore.id}/optimization/configuration/states/${response.data.id}/`)
          } else {
            dispatch(addNotifications([successNotification("Import", response.data)]))
            retrieveDetail()
          }
        })
        .catch(error => {
          if (error.response.headers["content-type"] === "text/csv") {
            fileDownload(error.response.data, `ERROR_${object.name}_${object.type}_${datetimeStr()}.csv`)
            dispatch(addNotifications([errorNotification("Error import", t("An error occured during the import, see more details in the error report file."))]))
          } else {
            dispatch(addNotifications([errorNotification("Error import", error.message)]))
          }
        })
    }
  }

  const onRowClick = rowId => {
    navigate(`/sites/${siteStore.id}/optimization/configuration/states/${rowId}/`)
  }

  const rowClassName = data => {
    return {
      "history-row": data.id.toString() === state.id
    }
  }

  const menuEntries = [
    {
      label: t("Template"),
      icon: "fa-regular fa-file",
      command: () => setVisible(true)
    },
    {
      label: t("Import"),
      icon: "fa-solid fa-file-import",
      command: () => hiddenFileInput && hiddenFileInput.current.click()
    },
    {
      label: t("Export"),
      icon: "fa-solid fa-file-export",
      command: getExport
    }
  ]

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      state &&
      state.id &&
      dispatch(
        updateBreadcrumb([
          { label: t("Sites"), url: "/sites/" },
          { label: siteStore.pretty_name ?? siteStore.name, url: `/sites/${siteStore.id}/` },
          { label: t("Optimization"), url: `/sites/${siteStore.id}/optimization/` },
          { label: t("States"), url: `/sites/${siteStore.id}/optimization/configuration/states/` },
          { label: state.name, url: `/sites/${siteStore.id}/optimization/configuration/states/${state.id}/` }
        ])
      )
  }, [siteStore.id, state])

  useEffect(() => {
    if (state) {
      setLoading(false)
      setObject(state)
      // setData(state)
    }
  }, [state])

  // useEffect(() => {
  //   const getGW = async () => {
  //     // TODO: remove when the api will no longer paginate
  //     await paginatedRequest(`connectivity/sites/${siteStore.id}/gateways/`, auth.userData.access_token)()
  //       .then(results => {
  //         console.log(results)
  //         // setGateways(results)
  //       })
  //       .catch(error => {
  //         dispatch(addNotifications([errorNotification("Error", error.message)]))
  //       })
  //
  //     // TODO: uncomment when the API will no longer paginate
  //     /* await getGateways(siteId, auth.userData.access_token).then(response => {
  //       setGateways(response.data.results)
  //     }).catch(error => {
  //       dispatch(addNotifications([errorNotification("Error", error.message)]))
  //     }) */
  //   }
  //
  //   edit && getGW()
  // }, [edit])

  useEffect(() => {
    const retrieveHistory = async () => {
      await getHistory(siteStore.id, object.history_id, object.type, auth.userData.access_token)
        .then(response => {
          setHistoryStates(response.data.results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }

    if (siteStore.id && object && !edit) {
      retrieveHistory()
    }
  }, [siteStore.id, object])

  return (
    <>
      {object ? (
        <>
          <ReturnButton
            label={t("Back to state list")}
            onClick={() => navigate(`/sites/${siteStore.id}/optimization/configuration/states`)}
          />

          <div className="base-content-title">
            <h2>
              {t("State")} {object.name}
            </h2>
            <div className="date">{new Date(object.updated_at).toLocaleString()}</div>
            <div className={`round ${object.active === "Yes" ? "round-active" : "round-not-active"}`} />
          </div>

          <ListActions>
            <Button
              rounded
              severity="info"
              label={t("Deploy")}
              icon="fa-solid fa-angles-up"
              outlined
              disabled
            />
            <Button
              rounded
              severity="info"
              label={t("Download")}
              icon="fa-solid fa-download"
              onClick={getArchive}
            />
            <Link to={`/sites/${siteStore.id}/optimization/configuration/states/${object.id}/delete/`}>
              <Button
                rounded
                severity="danger"
                label={t("Delete")}
                icon="fa-solid fa-trash-can"
              />
            </Link>
          </ListActions>

          <section className="list-section">
            <div className="list-section-head">
              <h3>{t("History")}</h3>
            </div>
            <ListLayout
              columns={[
                { field: "active", header: t("Deployed") },
                {
                  field: "created_at",
                  header: t("Created at"),
                  processValue: value => {
                    return new Date(value).toLocaleString()
                  }
                },
                { field: "updated_by", header: t("Updated by") },
                {
                  field: "updated_at",
                  header: t("Updated at"),
                  processValue: value => {
                    return new Date(value).toLocaleString()
                  }
                },
                { field: "comment", header: t("Comment") },
                { field: "state_values_number", header: t("State values") }
              ]}
              value={historyStates}
              objectName={t("State history")}
              onRowClick={onRowClick}
              rowClassName={rowClassName}
            />
          </section>

          <section className="list-section">
            <div className="list-section-head">
              <h3>{t("State values")}</h3>
            </div>
            <ListActions entries={menuEntries}>
              <input
                className="hidden"
                ref={hiddenFileInput}
                type="file"
                onChange={postImport}
              />
            </ListActions>
            <ListLayout
              columns={[
                { field: "point_label", header: t("Point") },
                { field: "value", header: t("Value") },
                { field: "comment", header: t("Comment") }
              ]}
              value={object.state_values}
              objectName={t("State values")}
            />
          </section>

          {visible && (
            <StateValuesTemplate
              state={object}
              siteId={siteStore.id}
              visible={visible}
              setVisible={setVisible}
            />
          )}
        </>
      ) : (
        <Loader />
      )}

      {/*
      <OverView
        object={object}
        model={StateForm(gateways)}
        overviewModel={StateOverview()}
        updateObject={updateObject}
        data={data}
        setData={setData}
        errors={errors}
        edit={edit}
        setEdit={setEdit}
        loading={loading}
        deleteUrl={`/sites/${siteId}/optimization/configuration/states/${stateId}/delete/`}
      />
      */}
    </>
  )
}
