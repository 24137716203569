import axios from "axios"

const header = token => ({
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Authorization": `Bearer ${token}`
  }
})

export async function retrieveList(siteId, queryParams, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_periods/jira/paginated/${queryParams}`, header(token))
}

export async function retrieve(siteId, unavailabilityPeriodId, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_periods/jira/${unavailabilityPeriodId}/`, header(token))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_periods/jira/`, data, header(token))
}

export async function update(siteId, unavailabilityPeriodId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_periods/jira/${unavailabilityPeriodId}/`, data, header(token))
}

export async function remove(siteId, unavailabilityPeriodId, token) {
  return await axios.delete(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_periods/jira/${unavailabilityPeriodId}/`, header(token))
}

export async function getUnavailabilityCategories(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_categories/jira/`, header(token))
}

export async function removeUnavailabilityPeriods(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_periods/jira/bulk-delete/`, ids, header(token))
}

export async function getAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_periods/jira/allvalues`, header(token))
}
