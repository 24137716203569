import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { getCount, remove } from "../api"

export default function Delete() {
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["assets"])
  const { id } = useParams()
  const [relatedObjects, setRelatedObjects] = useState(null)
  const [processing, setProcessing] = useState(false)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const count = async () => {
    await getCount(id, auth.userData.access_token)
      .then(response => {
        setRelatedObjects(response.data)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        displayNotification(errorNotification("Error", message))
      })
  }

  const removeObject = async () => {
    setProcessing(true)
    await remove(id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Delete", t("The OBJECT_NAME has been removed."))])
        navigate("/LIST_URL")
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        displayNotification([errorNotification("Error", message)])
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    //dispatch(updateSideBar({menuName: "site", siteId: siteId})

    /* dispatch(
    updateBreadcrumb([
      {label: "Home", url: "/"},
      {label: "Templates", url: "/templates/"},
    ])
    ) */
    count()
  }, [])

  return (
    <>
      <DeleteConfirmation
        objectType="OBJECT_NAME"
        remove={removeObject}
        relatedObjects={relatedObjects}
        goBack={() => navigate(-1)}
        processing={processing}
      />
    </>
  )
}
