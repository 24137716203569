import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ButtonWithLoader, FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add, getAutomationPoints, getAutomationPointsAllValues, getEquipmentAllValues, getEquipments, getParameters, getStores } from "../api"
import { Point } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])

  const [data, setData] = useState({
    parameter: 1,
    time_series_storage_info: {
      acceptable_values: [
        { original_point_value: 0, gateway_point_value: 0, gateway_point_text: "", gateway_point_value_change: false },
        { original_point_value: 1, gateway_point_value: 1, gateway_point_text: "", gateway_point_value_change: false }
      ]
    }
  })
  const [errors, setErrors] = useState({})
  const [parameters, setParameters] = useState([])
  const [stores, setStores] = useState([])
  const [processing, setProcessing] = useState(false)
  const [loadingParameters, setLoadingParameters] = useState(false)
  const [loadingStores, setLoadingStores] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const create = async function (e) {
    e.preventDefault()
    setProcessing(true)
    let pointData = Object.assign({}, data)
    let nullValues = ["", undefined, null]
    if (pointData.time_series_storage_info && nullValues.includes(pointData.time_series_storage_info.store_type)) {
      delete pointData.time_series_storage_info
    }
    if (pointData.time_series_storage_info && pointData.time_series_storage_info.store_type === "gauge") {
      delete pointData.time_series_storage_info.acceptable_values
    }
    await add(siteStore.id, pointData, auth.userData.access_token)
      .then(response => {
        dispatch(addNotifications([successNotification("Create", t("The point has been created."))]))
        navigate(`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/${response.data.id}`)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setProcessing(false))
  }

  const retrieveEquipments = async (queryParams = {}) => {
    return await getEquipments(siteStore.id, auth.userData.access_token, queryParams)
  }

  const getEquipmentValues = async () => {
    return await getEquipmentAllValues(siteStore.id, auth.userData.access_token)
  }

  const retrieveParameters = async () => {
    setLoadingParameters(true)
    await getParameters(auth.userData.access_token)
      .then(response => {
        response.data.results && setParameters(response.data.results)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoadingParameters(false))
  }

  const retrieveAutomationPoints = async (queryParams = {}) => {
    return await getAutomationPoints(siteStore.id, auth.userData.access_token, { ...queryParams, node_equipment: data.equipment })
  }

  const getAutomationPointValues = async () => {
    return await getAutomationPointsAllValues(siteStore.id, auth.userData.access_token)
  }

  const retrieveStores = async () => {
    setLoadingStores(true)
    await getStores(siteStore.id, data.time_series_storage_info.store_type, auth.userData.access_token)
      .then(response => {
        response.data.results && setStores(response.data.results)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoadingStores(false))
  }

  useEffect(() => {
    if (siteStore.id) {
      siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      retrieveParameters()
    }
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      organizationStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Building"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/`
          },
          {
            label: t("Points"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/`
          },
          {
            label: t("Create"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/create/`
          }
        ])
      )
  }, [siteStore.id, organizationStore.id])

  useEffect(() => {
    data.time_series_storage_info.store_type && retrieveStores()
  }, [data.time_series_storage_info.store_type])

  return (
    <>
      {data && (
        <>
          <div className="base-content-title">
            <h1 className="title">{t("Create point")}</h1>
          </div>

          <form
            className="form-view"
            onSubmit={create}
          >
            <FormContent
              model={Point(retrieveEquipments, getEquipmentValues, "", retrieveAutomationPoints, getAutomationPointValues, "", parameters, stores, loadingParameters, loadingStores)}
              data={data}
              setData={setData}
              errors={errors}
            />
            <div className="action-buttons-wrapper">
              <ButtonWithLoader
                rounded
                severity="success"
                label={t("Save")}
                type="submit"
                disabled={processing || loadingParameters || loadingStores}
                icon="fa-solid fa-floppy-disk"
              />
              <Button
                rounded
                severity="info"
                label={t("Cancel")}
                icon="fa-solid fa-xmark"
                disabled={processing}
                onClick={goBack}
              />
            </div>
          </form>
        </>
      )}
    </>
  )
}
