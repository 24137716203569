import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { SpaceConstraintRouteContext } from "../../space-constraint/routes"
import { update } from "../api"
import { SpaceConstraintDetail } from "../models"
import { SpaceConstraintDetailRouteContext } from "../routes"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const { spaceConstraint } = useContext(SpaceConstraintRouteContext)
  const { spaceConstraintDetail, retrieveDetail } = useContext(SpaceConstraintDetailRouteContext)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [object, setObject] = useState(null)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [loading, setLoading] = useState(true)

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await update(siteStore.id, spaceConstraint.id, spaceConstraintDetail.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Space constraint detail", t("The space constraint detail has been updated."))]))
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Space constraint detail", message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    if (spaceConstraintDetail) {
      setLoading(false)
      setObject({
        ...spaceConstraintDetail,
        ...{
          start_validity: spaceConstraintDetail.start_validity.split("Z")[0],
          end_validity: spaceConstraintDetail.end_validity.split("Z")[0]
        }
      })
    }
  }, [spaceConstraintDetail])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && spaceConstraint.id && spaceConstraintDetail && spaceConstraintDetail.id) {
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          {
            label: t("Space constraints"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/`
          },
          {
            label: spaceConstraint.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraint.id}/`
          },
          {
            label: t("Details"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraint.id}/details`
          },
          {
            label: t("Detail"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraint.id}/details/${spaceConstraintDetail.id}/`
          }
        ])
      )
    }
  }, [siteStore.id, spaceConstraint.id, spaceConstraintDetail])

  return (
    <>
      {object && (
        <>
          <OverView
            object={object}
            model={SpaceConstraintDetail()}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            loading={loading}
            processing={processing}
            setEdit={setEdit}
            deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/space-constraints/${spaceConstraint.id}/details/${object.id}/delete/`}
          />
        </>
      )}
    </>
  )
}
